<template>
  <router-view></router-view>
</template>

<script>
  export default {
    name: "TeacherLoginView",
  };
</script>

<style lang="stylus" scoped>
</style>
