<template>
  <b-modal
    ref="add_new_toppings_modal"
    centered
    @hidden="resetForm"
    modal-class="custom-modal custom-modal--not-header custom-modal--not-footer"
  >
    <div class="d-flex justify-content-end mb-3">
      <div
        tabindex="0"
        :aria-label="$t('ad_close_popup_aria_label')"
        class="btn-close-modal icon-cancel"
        @click="hideModal"
        @keypress.prevent.space="hideModal"
        @keypress.prevent.enter="hideModal"
      ></div>
    </div>
    <div
      class="mb-4"
      tabindex="0"
      :aria-label="
        $t('ad_dashboard_new_topping') +
          ' ' +
          $t('ad_dashboard_new_topping_instructions')
      "
    >
      <h3 class="text-center main-title-modal">
        {{ $t("ad_dashboard_new_topping") }}:
      </h3>
      <div class="text-center common-text w-75 m-auto">
        {{ $t("ad_dashboard_new_topping_instructions") }}
      </div>
    </div>

    <b-form @submit.prevent="onSubmit" class="pl-2 pr-2">
      <b-form-group label-for="name_topping_field">
        <b-form-input
          id="name_topping_field"
          v-model="form.nameTopping"
          type="text"
          :placeholder="$t('ad_dashboard_new_topping_name')"
          class="common-input"
          max-legth="50"
        ></b-form-input>

        <div class="container-alert mt-1" v-if="formNameError">
          <b-alert
            variant="danger"
            dismissible
            @dismissed="formNameError = null"
            show
            class="custom-alert-ja has-icon-alert"
            tabindex="0"
            :aria-label="formNameError"
          >
            <div class="title-alert">{{ formNameError }}</div>
            <div class="alert-type-icon icon-cancel-circled"></div>
          </b-alert>
        </div>
      </b-form-group>
      <div>
        <div
          v-if="hasImagePreview"
          class="common-input common-input-file is-container-preview mb-2"
        >
          <div
            class="container-file-preview"
            :style="`background-image: url(${imagePreviewUrl})`"
          >
            <button
              class="btn-delete-file"
              type="button"
              tabindex="0"
              :aria-label="$t('ad_remove_img_aria_label')"
              @click="removeImg"
              @keypress.prevent.space="removeImg"
              @keypress.prevent.enter="removeImg"
            ></button>
          </div>
        </div>
        <div class="container-input-file" v-else>
          <b-form-file
            v-model="form.image"
            placeholder=""
            drop-placeholder=""
            id="image_field"
            :browse-text="$t('ad_dashboard_upload_image_drop')"
            accept=".jpg, .png"
            class="common-input common-input-file mb-1"
            :file-name-formatter="fileNameFormatter"
            :aria-label="
              $t('ad_dashboard_upload_image') +
                ' ' +
                $t('ad_dashboard_upload_image_drop')
            "
          ></b-form-file>
          <div class="copy-label-text" tabindex="-1">
            <div>
              <strong>{{ $t("ad_dashboard_upload_image") }}</strong>
            </div>
            <div>{{ $t("ad_dashboard_upload_image_drop") }}</div>
          </div>
        </div>

        <div class="container-alert mt-1" v-if="formImageError">
          <b-alert
            variant="danger"
            dismissible
            @dismissed="formImageError = null"
            show
            class="custom-alert-ja has-icon-alert"
            tabindex="0"
            :aria-label="formImageError"
          >
            <div class="title-alert">{{ formImageError }}</div>
            <div class="alert-type-icon icon-cancel-circled"></div>
          </b-alert>
        </div>
      </div>

      <div class="container-alert" v-if="showValidation && errorMessage">
        <b-alert
          variant="danger"
          dismissible
          @dismissed="errorMessage = null"
          show
          class="custom-alert-ja has-icon-alert"
          tabindex="0"
          :aria-label="errorMessage"
        >
          <div class="title-alert">{{ errorMessage }}</div>
          <div class="alert-type-icon icon-cancel-circled"></div>
        </b-alert>
      </div>

      <div class="container-alert" v-if="showValidation && successMessage">
        <b-alert
          variant="success"
          dismissible
          @dismissed="successMessage = null"
          show
          class="custom-alert-ja has-icon-alert"
          tabindex="0"
          :aria-label="successMessage"
        >
          <div class="title-alert">{{ successMessage }}</div>
          <div class="alert-type-icon icon-ok-circled"></div>
        </b-alert>
      </div>

      <div v-else class="d-flex justify-content-end mt-4">
        <b-button
          type="submit"
          class="common-btn common-btn--small btn-w-auto pl-4 pr-4"
          tabindex="0"
          :aria-label="$t('ad_button_add')"
          ref="btnSubmitAddToppings"
          >{{ $t("ad_button_add") }}</b-button
        >
      </div>
    </b-form>
  </b-modal>
</template>

<script>
import { Utils, Spinner, UtilsI18n } from "@/core/Utils";
import { GlobalSetup } from "../Services";
import { TranslationsService } from "@/core/Services";
import { DEFAULT_LANGUAGE_ID } from "@/constant";

export default {
  name: "AddNewToppingsModal",
  props: {},
  data() {
    return {
      form: {
        nameTopping: "",
        image: null
      },
      hasImagePreview: false,
      imagePreviewUrl: null,
      showValidation: false,
      errorMessage: null,
      successMessage: null,
      formNameError: null,
      formImageError: null
    };
  },
  mounted() {},
  methods: {
    showModal() {
      this.$refs["add_new_toppings_modal"].show();
    },
    hideModal() {
      this.$refs["add_new_toppings_modal"].hide();
    },
    onSubmit() {
      this.saveNewTopping();
      this.blurElement(this.$refs.btnSubmitAddToppings);
    },
    fileNameFormatter() {
      return "";
    },
    resetForm() {
      this.form = {
        nameTopping: "",
        image: null
      };

      this.resetFormValidations();
    },
    saveNewTopping() {
      Spinner.start();

      if (!this.isValidForm()) {
        Spinner.stop();
        return;
      }

      const newImg = this.getNewFileImage(this.form.image);
      const formData = new FormData();
      const toppingName = `st_toppings_${this.form.nameTopping
        .replaceAll(/\s/g, "")
        .toLowerCase()}`;

      const payloadTranslations = [
        {
          key: toppingName,
          value: this.form.nameTopping
        }
      ];

      formData.append("name", toppingName);
      formData.append("image", newImg);

      GlobalSetup.addTopping(formData)
        .then(async response => {
          await TranslationsService.saveTranslations(
            DEFAULT_LANGUAGE_ID,
            payloadTranslations
          );

          UtilsI18n.loadMessages().then(({ lang, messages }) => {
            this.$i18n.setLocaleMessage(lang, messages);
          });

          this.showValidation = true;
          this.successMessage = this.$t("ad_dashboard_added_success");
          this.$emit("addTopping", response["topping"]);

          Spinner.stop();

          setTimeout(() => {
            this.hideModal();
          }, 1000);
        })
        .catch(err => {
          this.showValidation = true;
          this.errorMessage = this.$t("ad_error_unexpected");

          if (err) {
            if (err.message) {
              this.errorMessage = err.message;
            }
          }

          Spinner.stop();
        });
    },
    resetFormValidations() {
      this.showValidation = false;
      this.errorMessage = null;
      this.successMessage = null;
      this.formNameError = null;
      this.formImageError = null;
    },
    isValidForm() {
      this.resetFormValidations();

      let valid = true;
      if (!this.form.nameTopping || this.form.nameTopping.trim().length == 0) {
        this.formNameError = this.$t("ad_error_required");
        valid = false;
      }

      if (
        this.form.nameTopping.trim().length > 0 &&
        this.form.nameTopping.length > 50
      ) {
        this.formNameError = this.$t("ad_dashboard_error_topping_name");
        valid = false;
      }

      if (!this.form.image) {
        this.formImageError = this.$t("ad_error_required");
        valid = false;
      }

      if (
        this.form.image &&
        !Utils.isValidFile(["image/png", "image/jpeg"], this.form.image)
      ) {
        this.formImageError = this.$t("ad_error_invalid_format");
        valid = false;
      }

      return valid;
    },
    removeImg() {
      this.form.image = null;
      this.hasImagePreview = false;
      this.imagePreviewUrl = null;
    },
    generatePreviewImg(img) {
      if (img) {
        let reader = new FileReader();

        reader.onload = function(e) {
          this.imagePreviewUrl = e.target.result;
          this.hasImagePreview = true;
        }.bind(this);

        reader.readAsDataURL(img);
      } else {
        this.imagePreviewUrl = null;
        this.hasImagePreview = false;
      }
    },
    getNewFileImage(imgFile) {
      let newImg = imgFile;

      if (newImg) {
        const currentName = newImg.name;
        const newFileName = currentName.split(" ").join("");
        newImg = new File([imgFile], newFileName);
      }

      return newImg;
    }
  },
  watch: {
    "form.image": function(val) {
      this.generatePreviewImg(val);
    }
  }
};
</script>

<style lang="stylus" scoped></style>
