<template>
  <b-form @submit.prevent="onSubmit">
    <div class="common-card d-flex w-100">
      <div class="container-terms-checkbox">
        <h6 class="custom-title title-h6 mb-2">{{ $t("ad_label_active") }}</h6>
        <b-form-checkbox v-model="form.active" class="custom-checkbox-input custom-checkbox-secondary"
                         tabindex="0" :aria-label="$t('ad_select_unselect_option', {optionName: ''})"></b-form-checkbox>
      </div>
      <div class="container-terms-url">
        <h6 class="custom-title title-h6 mb-2" tabindex="0"
            :aria-label="label">{{ label }}</h6>
        <div class="d-flex justify-content-around mb-2">
          <b-form-group class="mb-0 w-100 mr-3">
            <b-form-input class="common-input common-input-secondary w-100 mb-0" v-model="form.url" type="text" maxlength="150"></b-form-input>
          </b-form-group>

          <b-button type="submit"
                    class="common-btn common-btn-secondary common-btn--small"
                    tabindex="0" :aria-label="$t('ad_button_save')"
                    ref="btnSubmitTerms">{{ $t("ad_button_save") }}</b-button>
        </div>
      </div>
    </div>
    <div class="mt-2" v-if="showValidation">
      <div class="container-alert mt-0" v-if="errorMessage">
        <b-alert variant="danger" dismissible @dismissed="errorMessage=null"
                 show class="custom-alert-ja has-icon-alert" tabindex="0" :aria-label="errorMessage">
          <div class="title-alert">{{ errorMessage }}</div>
          <div class="alert-type-icon icon-cancel-circled"></div>
        </b-alert>
      </div>

      <div class="container-alert mt-0" v-if="successMessage">
        <b-alert variant="success" show dismissible @dismissed="successMessage=null"
                 class="custom-alert-ja has-icon-alert" tabindex="0" :aria-label="successMessage">
          <div class="title-alert">{{ successMessage }}</div>
          <div class="alert-type-icon icon-ok-circled"></div>
        </b-alert>
      </div>

    </div>
  </b-form>
</template>
<script>
  import { Utils } from "@/core/Utils"
  import { GlobalSetup } from "../Services"

  export default {
    name: "TermsPrivacyPolicyItem",
    props: {
      formData: {
        type: Object,
        required: true,
        default: () => {
          return {
            active: false,
            url: ""
          }
        }
      },
      label: {
        type: String,
        required: true
      }
    },
    data(){
      return {
        form: {
          active: false,
          url: ""
        },
        showValidation: false,
        errorMessage: null,
        successMessage: null
      }
    },
    mounted(){
      this.form = this.formData
    },
    methods: {
      onSubmit(){
        this.saveData();
        this.blurElement(this.$refs.btnSubmitTerms);
      },
      saveData(){
        if(!this.isValidForm()){
          return
        }
        const dataForm = {
          setupSection: this.form.sectionId
        }

        if(this.form.isTermsManager){
          dataForm.termsActive = this.form.active
          dataForm.termsUrl = this.form.url ? this.form.url : null
        }else{
          dataForm.policyActive =  this.form.active
          dataForm.policyUrl =  this.form.url ? this.form.url : null
        }


        GlobalSetup.setGlobalSetup(dataForm).then(() => {
          this.showValidation = true
          this.successMessage = this.$t("ad_success_update")

          setTimeout(() => {
            this.showValidation = false
            this.successMessage = null
          }, 1000)
        }).catch((err) => {
          this.showValidation = true;
          this.errorMessage = this.$t("ad_error_unexpected")

          if (err) {
            if (err.message) {
              this.errorMessage = err.message
            }
          }
        })
      },
      isValidForm(){
        this.showValidation = false
        this.errorMessage = null
        this.successMessage = null

        if(this.form.active && !this.form.url){
          this.showValidation = true
          this.errorMessage = this.$t("ad_error_required")
          return false
        }
        if(this.form.url && this.form.url.length > 150){
          this.showValidation = true
          this.errorMessage = this.$t("ad_dashboard_error_url_length")
          return false
        }
        if(this.form.url && !Utils.isValidUrl(this.form.url)){
          this.showValidation = true
          this.errorMessage = this.$t("ad_dashboard_error_url_invalid")
          return false
        }

        return true
      }
    }
  };
</script>

<style lang="stylus" scoped>
  @import "Styles/terms_privacy_policy.styl";
</style>
