<template>
  <section>
    <div class="">
      <h3 class="custom-title title-h3 text-uppercase mb-2"
          tabindex="0" :aria-label="$t('ad_setupgame_general')">{{ $t("ad_setupgame_general") }}</h3>
      <p class="common-text" tabindex="0" :aria-label="$t('ad_setupgame_general_instructions')">{{ $t("ad_setupgame_general_instructions") }}</p>
      <br>
    </div>
    <b-form @submit.prevent="onSubmit" v-if="showForm">
      <div class="container-setup-cards">
        <!-- Select Game Mode Card -->
        <div class="container-setup-cards--card">
          <div class="common-card pr-2 pl-2 pt-4 pb-5">
            <div class="text-center pt-2 pb-2">
              <h6 class="custom-title title-h6" tabindex="0" :aria-label="$t('ad_setupgame_mode')">{{ $t("ad_setupgame_mode") }}</h6>
              <br>
              <b-dropdown id="game_mode" class="custom-dropdown" :disabled="disabledInput">
                <template #button-content>
                  <span class="d-inline-block mr-2">{{gameModeText}}</span>
                </template>
                <b-dropdown-item-button v-for="opt in gameModeOptions"
                                 :key="`game-mode-opt-${opt.id}`"
                                 @click="updateGameMode(opt)" >
                  {{ $t(opt.text) }}
                </b-dropdown-item-button>
              </b-dropdown>
            </div>
          </div>

          <div class="container-alert mt-1" v-if="formErrors.gameMode">
            <b-alert variant="danger" dismissible @dismissed="formErrors.gameMode=null"
                     show class="custom-alert-ja has-icon-alert" tabindex="0" :aria-label="formErrors.gameMode">
              <div class="title-alert">{{ formErrors.gameMode }}</div>
              <div class="alert-type-icon icon-cancel-circled"></div>
            </b-alert>
          </div>

        </div>
        <!-- Duration Card -->
        <div class="container-setup-cards--card">
          <div class="common-card pr-2 pl-2 pt-4 pb-4">
            <div class="text-center pt-2 pb-1">
              <h6 class="custom-title title-h6 mb-0" tabindex="0" :aria-label="$t('ad_setupgame_duration')">
                <span class="icon-info-circled tooltip-game-duration" :aria-label='$t("ad_setupgame_duration_instructions")'
                  tabindex="0" role="button" aria-pressed="false"
                  @click="showDurationInfo()"
                  @keyup.enter="showDurationInfo()"
                  @keyup.space="showDurationInfo()"></span>
                <span>{{ $t("ad_setupgame_duration") }}</span>
              </h6>
              <div class="smallest-text mb-3" tabindex="0"
                   :aria-label="durationGameDesc">
                {{ durationGameDesc }}
              </div>

              <div class="mb-0 container-inputs-duration-game">
                <div class="input-duration-game" tabindex="0" :aria-label="$t('ad_setupgame_duration_min')">
                  <div class="custom-title small-title text-center text-uppercase mb-1">{{ $t("ad_setupgame_duration_min") }}</div>
                  <b-form-input
                    id="duration_min"
                    v-model="form.gameDuration.min"
                    type="text"
                    placeholder=""
                    maxlength="2"
                    class="common-input common-input-secondary not-disabled-styles text-center mb-0"
                    :disabled="disabledInput"
                  ></b-form-input>
                </div>
                <div class="input-duration-game" tabindex="0" :aria-label="$t('ad_setupgame_duration_sec')">
                  <div class="custom-title small-title text-center text-uppercase mb-1">{{ $t("ad_setupgame_duration_sec") }}</div>
                  <b-form-input
                    id="duration_seg"
                    v-model="form.gameDuration.seg"
                    type="text"
                    placeholder=""
                    maxlength="2"
                    class="common-input common-input-secondary not-disabled-styles text-center mb-0"
                    :disabled="disabledInput"
                  ></b-form-input>
                </div>
              </div>
            </div>
          </div>

          <div class="container-alert mt-1" v-if="formErrors.gameDuration">
            <b-alert variant="danger" dismissible @dismissed="formErrors.gameDuration=null"
                     show class="custom-alert-ja has-icon-alert" tabindex="0" :aria-label="formErrors.gameDuration">
              <div class="title-alert">{{ formErrors.gameDuration }}</div>
              <div class="alert-type-icon icon-cancel-circled"></div>
            </b-alert>
          </div>

        </div>
        <!-- Pizza Value Card -->
        <div class="container-setup-cards--card">
          <div class="common-card pr-2 pl-2 pt-4 pb-5">
            <div class="text-center pt-2 pb-1">
              <h6 class="custom-title title-h6 mb-3" tabindex="0" :aria-label="$t('ad_setupgame_value')">{{ $t("ad_setupgame_value") }}</h6>
              <br>
              <div class="container-pizza-value">
                <div class="currency-symbol"
                     v-if="globalData.currencySymbolPosition === 'Left'">
                  {{globalData.currencySymbol}}
                </div>
                <b-form-group class="mb-0 mr-2 ml-2">
                  <b-form-input
                    id="pizza_value"
                    v-model="form.pizzaPrice"
                    type="text"
                    placeholder=""
                    :disabled="disabledInput"
                    maxlength="7"
                    class="common-input common-input-secondary pizza-value-input not-disabled-styles text-center mb-0"
                  ></b-form-input>
                </b-form-group>
                <div class="currency-symbol"
                     v-if="globalData.currencySymbolPosition === 'Right'">
                  {{globalData.currencySymbol}}
                </div>
              </div>
            </div>
          </div>

          <div class="container-alert mt-1" v-if="formErrors.pizzaPrice">
            <b-alert variant="danger" dismissible @dismissed="formErrors.pizzaPrice=null"
                     show class="custom-alert-ja has-icon-alert" tabindex="0" :aria-label="formErrors.pizzaPrice">
              <div class="title-alert">{{ formErrors.pizzaPrice }}</div>
              <div class="alert-type-icon icon-cancel-circled"></div>
            </b-alert>
          </div>

        </div>
        <!-- Number of Toppings Card -->
        <div class="container-setup-cards--card">
          <div class="common-card pr-2 pl-2 pt-4 pb-5">
            <div class="text-center pt-2 pb-0">
              <h6 class="custom-title title-h6 mb-4"
                  tabindex="0" :aria-label="$t('ad_setupgame_toppings_num')">{{ $t("ad_setupgame_toppings_num") }}</h6>
              <br>
              <div class="container-increasing-option">
                <div class="action-increasing mr-4" :class="{'disabled': disabledInput}"
                     @click="increaseValue(true, false)"
                     @keypress.prevent.space="increaseValue(true, false)"
                     @keypress.prevent.enter="increaseValue(true, false)"
                     tabindex="0" :aria-label="$t('ad_setupgame_decrease_val_aria_label')">-</div>

                <div class="increasing-value" tabindex="0" :aria-label="form.maxToppings">{{ form.maxToppings }}</div>

                <div class="action-increasing ml-4" :class="{'disabled': disabledInput}"
                     @click="increaseValue(true, true)"
                     @keypress.prevent.space="increaseValue(true, true)"
                     @keypress.prevent.enter="increaseValue(true, true)"
                     tabindex="0" :aria-label="$t('ad_setupgame_increase_val_aria_label')">+</div>
              </div>
            </div>
          </div>
        </div>
        <!-- Students per Team Card -->
        <div class="container-setup-cards--card">
          <div class="common-card pr-2 pl-2 pt-4 pb-5">
            <div class="text-center pt-2 pb-0">
              <h6 class="custom-title title-h6 mb-4"
                  tabindex="0" :aria-label="$t('ad_setupgame_students_team')">{{ $t("ad_setupgame_students_team") }}</h6>
              <br>
              <div class="container-increasing-option">
                <div class="action-increasing mr-4" :class="{'disabled': disabledInput}"
                     @click="increaseValue(false, false)"
                     @keypress.prevent.space="increaseValue(false, false)"
                     @keypress.prevent.enter="increaseValue(false, false)"
                     tabindex="0" :aria-label="$t('ad_setupgame_decrease_val_aria_label')">-</div>

                <div class="increasing-value" tabindex="0" :aria-label="form.maxStudentsPerTeam">{{ form.maxStudentsPerTeam }}</div>

                <div class="action-increasing ml-4" :class="{'disabled': disabledInput}"
                     @click="increaseValue(false, true)"
                     @keypress.prevent.space="increaseValue(false, true)"
                     @keypress.prevent.enter="increaseValue(false, true)"
                     tabindex="0" :aria-label="$t('ad_setupgame_increase_val_aria_label')">+</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="successMessage || errorMessage" class="mt-3 mb-3">
        <div class="container-alert" v-if="successMessage">
          <b-alert variant="success" show class="custom-alert-ja has-icon-alert"
                   dismissible @dismissed="successMessage=null"
                   tabindex="0" :aria-label="successMessage">
            <div class="title-alert">{{ successMessage }}</div>
            <div class="alert-type-icon icon-ok-circled"></div>
          </b-alert>
        </div>

        <div class="container-alert" v-if="errorMessage">
          <b-alert variant="danger" dismissible @dismissed="errorMessage=null"
                   show class="custom-alert-ja has-icon-alert"
                   tabindex="0" :aria-label="errorMessage">
            <div class="title-alert">{{ errorMessage }}</div>
            <div class="alert-type-icon icon-cancel-circled"></div>
          </b-alert>
        </div>
      </div>

      <div class="row justify-content-end no-gutters mt-3 mb-3">
        <div class="col-md-auto px-md-2">
          <b-button @click="resetGame()" :disabled="!disabledBtn"
                    class="common-btn common-btn--small mb-3 w-100"
                    :aria-label="$t('ad_setupgame_reset_game')"
                    ref="btnSubmitResetGame">
            {{ $t("ad_setupgame_reset_game") }}
          </b-button>
        </div>

        <div class="col-md-auto pl-md-2">
          <b-button type="submit" :disabled="disabledBtn"
                    class="common-btn common-btn--small mb-3 w-100"
                    :aria-label="$t('ad_setupgame_generate_code')"
                    ref="btnSubmitGenerateCode">
            {{ $t("ad_setupgame_generate_code") }}
          </b-button>
        </div>
      </div>

    </b-form>
    <reset-game-modal ref="reset_game_modal" v-on="$listeners"/>
    <game-duration-modal ref="game_duration_modal"/>
    <div class="separator mt-4 mb-3"></div>
    <br>
  </section>
</template>

<script>
  import { Utils } from "@/core/Utils"
  import { GameSetup } from "../Services"
  import { TeacherConstants } from "../Constants"
  import ResetGameModal from "./ResetGameModal.vue"
  import GameDurationModal from "./GameDurationModal.vue"

  export default {
    name: "GeneralSetUp",
    components: { ResetGameModal, GameDurationModal },
    props: {
      dataSetup: {
        type: Object,
        required: true,
        default: () => {
          return {
            gameMode: TeacherConstants.GAME_MODE.INDIVIDUAL.value,
            gameDuration: 0,
            pizzaPrice: 0,
            maxToppings: 1,
            maxStudentsPerTeam: 5
          }
        }
      },
      globalData: {
        type: Object,
        required: true,
        default: () => {
          return {
            currencySymbol: null,
            currencySymbolPosition: null
          }
        }
      }
    },
    data(){
      return {
        gameModeOptions: TeacherConstants.GAME_MODE,
        gameModeText: null,
        form: {
          gameMode: null,
          gameDuration: {
            min: null,
            seg: null
          },
          pizzaPrice: null,
          maxToppings: 1,
          maxStudentsPerTeam: 5
        },
        formErrors: {
          gameMode: null,
          gameDuration: null,
          pizzaPrice: null
        },
        showForm: false,
        errorMessage: null,
        successMessage: null,
        disabledBtn: false,
        disabledInput: false,
        maxPizzaPrice: 10000
      }
    },
    beforeMount(){
      this.form = this.dataSetup
      this.form.pizzaPrice = this.dataSetup.pizzaPrice ? this.dataSetup.pizzaPrice : null
      this.gameModeText = this.$t("ad_setupgame_select_mode")
      if (this.form.gameMode) {
        if (this.form.gameMode == TeacherConstants.GAME_MODE.INDIVIDUAL.value) {
          this.gameModeText = this.$t(TeacherConstants.GAME_MODE.INDIVIDUAL.text)
        }
        else if (this.form.gameMode == TeacherConstants.GAME_MODE.SHARED.value) {
          this.gameModeText = this.$t(TeacherConstants.GAME_MODE.SHARED.text)
        }
      }

      if(this.form.gameDuration > 0){
        const padNumber = Utils.padNumber(this.form.gameDuration, 6)
        const gameDuration = Utils.stringToTimeFormat(padNumber)
        this.form.gameDuration = {
          hour: gameDuration[0],
          min: gameDuration[1],
          seg: gameDuration[2]
        }
      }else{
        this.form.gameDuration = {
          hour: null,
          min: null,
          seg: null
        }
      }

      this.disabledBtn = !!this.dataSetup.gameCode
      this.disabledInput = !!this.dataSetup.gameCode
      this.showForm = true
    },
    methods: {
      onSubmit(){
        this.generateGameCode()
        this.blurElement(this.$refs.btnSubmitGenerateCode);
      },
      resetGame(){
        this.blurElement(this.$refs.btnSubmitResetGame);
        this.$refs["reset_game_modal"].showModal()
      },
      showDurationInfo(){
        this.$refs["game_duration_modal"].showModal()
      },      
      updateGameMode(opt){
        this.form.gameMode = opt.value
        this.gameModeText = this.$t(opt.text)
        this.$emit("updateGameMode", opt.value)
      },
      increaseValue(isTopping, increase){
        if(this.disabledInput){
          return
        }

        if(isTopping){
          const increasedVal = this.form.maxToppings + 1
          const decreasedVal = this.form.maxToppings - 1
          if(increase && !(increasedVal > TeacherConstants.TOPPINGS.MAX)){
            this.form.maxToppings = increasedVal
          } else if(!increase && !(decreasedVal < TeacherConstants.TOPPINGS.MIN)){
            this.form.maxToppings = decreasedVal
          }
        }else{
          const increasedVal = this.form.maxStudentsPerTeam + 1
          const decreasedVal = this.form.maxStudentsPerTeam - 1
          if(increase && !(increasedVal > TeacherConstants.STUDENTS_PER_TEAM.MAX)){
            this.form.maxStudentsPerTeam = increasedVal
          } else if(!increase && !(decreasedVal < TeacherConstants.STUDENTS_PER_TEAM.MIN)){
            this.form.maxStudentsPerTeam = decreasedVal
          }
        }
      },

      isValidForm(){
        let isValid = true
        this.formErrors = {
          gameMode: null,
          gameDuration: null,
          pizzaPrice: null
        }

        this.errorMessage = null
        this.successMessage = null

        if(!this.form.gameMode){
          this.formErrors.gameMode = this.$t("ad_error_required")
          isValid = false
        }

        if(!this.form.gameDuration ||
          !this.form.gameDuration.min ||
          !this.form.gameDuration.seg) {
          this.formErrors.gameDuration = this.$t("ad_setupgame_duration_error")
          isValid = false

        }else if(this.form.gameDuration){
          if(!Utils.strIsNumeric(this.form.gameDuration.min) ||
            !Utils.strIsNumeric(this.form.gameDuration.seg)){

            this.formErrors.gameDuration = this.$t("ad_setupgame_duration_error_2")
            isValid = false

          }else if((this.form.gameDuration.min && this.form.gameDuration.min.length > 2) ||
            (this.form.gameDuration.seg && this.form.gameDuration.seg.length > 2)){

            this.formErrors.gameDuration = this.$t("ad_setupgame_duration_error_3")
            isValid = false

          }else if(this.getGameDurationInt(this.form.gameDuration.min) <= 0 &&
            this.getGameDurationInt(this.form.gameDuration.seg) <= 0){

            this.formErrors.gameDuration = this.$t("ad_setupgame_duration_error")
            isValid = false

          }else if(this.form.gameDuration.seg && this.getGameDurationInt(this.form.gameDuration.seg) > 59){
            this.formErrors.gameDuration = this.$t("ad_setupgame_duration_error_4")
            isValid = false
          }
        }

        if(!this.form.pizzaPrice || (this.form.pizzaPrice && parseInt(this.form.pizzaPrice) == 0)){
          this.formErrors.pizzaPrice = this.$t("ad_error_required")
          isValid = false
        }

        if(this.form.pizzaPrice){
          const pizzaPrice = parseFloat(this.form.pizzaPrice)
          const isInvalidDecimal = this.form.pizzaPrice.indexOf(".") === (this.form.pizzaPrice.length - 1)
          const isNegative = pizzaPrice < 0
          const isNumericFormat = Utils.isNumericFormat(this.form.pizzaPrice)

          if(pizzaPrice >= this.maxPizzaPrice){
            this.formErrors.pizzaPrice = this.$t("ad_error_less_than", {maxvalue: this.maxPizzaPrice})
            isValid = false
          }

          const decimals = this.form.pizzaPrice.split(".")[1]
          if(decimals && decimals.length > 2){
            this.formErrors.pizzaPrice = this.$t("ad_setupgame_value_max_decimal_error", {max_decimals: 2})
            isValid = false
          }

          if(isInvalidDecimal || isNegative || !isNumericFormat){
            this.formErrors.pizzaPrice = this.$t("ad_error_invalid_format")
            isValid = false
          }
        }

        return isValid;
      },
      generateGameCode(){
        this.disabledBtn = true
        if(!this.isValidForm()){
          this.disabledBtn = false
          return
        }

        const dataForm = {
          gameMode: this.form.gameMode,
          gameDuration: this.getGameDurationValToSave(),
          pizzaPrice: parseFloat(this.form.pizzaPrice),
          maxToppings: this.form.maxToppings,
          maxStudentsPerTeam: this.form.maxStudentsPerTeam,
        }

        const gameId = this.dataSetup.gameId

        GameSetup.generateGameCode(gameId, dataForm).then((response) => {
          this.disabledInput = true;
          GameSetup.setStorageGame(response.gameSetup);
          this.$emit("updateGameSetupData")
          this.$emit("startGetTeams")

          this.successMessage = this.$t("ad_setupgame_sucess_update_game")
          setTimeout(() => {
            this.successMessage = null
          }, 1000)

        }).catch((err) => {
          this.disabledBtn = false
          this.errorMessage = this.$t("ad_error_unexpected")

          if (err) {
            if (err.message) {
              this.errorMessage = err.message
            }
          }
        })
      },
      getGameDurationInt(val){
        return parseInt(val)
      },
      getGameDurationValToSave(){
        const min = this.getGameDurationInt(this.form.gameDuration.min)
        const seg = this.getGameDurationInt(this.form.gameDuration.seg)
        // value in seconds
        return parseInt( (min * 60) + seg )
      },
    },
    computed: {
      isSharedMode(){
        if(!this.dataSetup || this.dataSetup && !this.dataSetup.gameMode){
          return false
        }
        return this.dataSetup.gameMode == this.gameModeOptions.SHARED.value
      },
      durationGameDesc(){
        let desc = this.$t("ad_setupgame_duration_individual_description")
        if(this.isSharedMode === true){
          desc = this.$t("ad_setupgame_duration_shared_description")
        }

        return desc
      }
    }
  };
</script>

<style lang="stylus" scoped>
  @import "Styles/general_setup.styl";
</style>
