import SecurityView from "../SecurityView.vue"
import AdminLogin from "../Pages/AdminLogin.vue";
import AdminForgotPassword from "../Pages/AdminForgotPassword.vue"
import AdminChangePassword from "../Pages/AdminChangePassword.vue"
import AdminResetPassword from "../Pages/AdminResetPassword.vue"
import AdminVerifyEmailPassword from "../Pages/AdminVerifyEmailPassword.vue"

const routes = [
  {
    path: 'security',
    component: SecurityView,
    redirect: { name: 'AdminLogin' },
    children: [
      {
        path: 'login',
        name: 'AdminLogin',
        component: AdminLogin,
      },
      {
        path: 'forgot-password',
        name: 'AdminForgotPassword',
        component: AdminForgotPassword,
      },
      {
        path: 'change-password',
        name: 'AdminChangePassword',
        component: AdminChangePassword,
        meta: { authRequired: true, }
      },
      {
        path: 'reset-password',
        name: 'AdminResetPassword',
        component: AdminResetPassword,
      },
      {
        path: 'verify-email-password',
        name: 'AdminVerifyEmailPassword',
        component: AdminVerifyEmailPassword,
      },
    ]
  }
]

export default routes
