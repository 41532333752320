export default {
    data() {
        return {
            form: { email: "", password: ""},
            emailError: false,
            passwordError: false,
            emailErrors: [],
            passwordErrors: [],
            serviceError: false,
        }
    },
    methods: {
        isValidform() {
            const { email } = this.form;

            this.emailError = [];
            this.emailError = false;

            if (!this.validEmail(email)) {
                this.emailError = this.$t("ad_error_valid_email_required");
            }

            if( !this.emailErrors.length &&
                !this.passwordErrors.length &&
                !this.emailError ) {
                return true;
            }

            return false;
        },
        validEmail(email) {
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        }
    }
};
