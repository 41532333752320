<template>
  <base-form>
    <template v-slot:content>
      <header-form/>

      <h3 class="title-form-page" tabindex="0" :aria-label="$t('ad_changepassword_title')">{{ $t("ad_changepassword_title") }}</h3>
      <div class="desc-form-page" tabindex="0" :aria-label="$t('ad_changepassword_instructions')">{{ $t("ad_changepassword_instructions") }}</div>

      <b-form @submit.prevent="onSubmit" class="mr-auto ml-auto mt-5 w-75">
        <b-form-group label-for="current_password_field">
          <b-form-input
            id="current_password_field"
            v-model="form.currentPassword"
            type="password"
            :placeholder='$t("ad_changepassword_current_password")'
            class="common-input"
          ></b-form-input>

          <div>
            <div class="container-alert mt-1" v-if="currentPasswordError">
              <b-alert variant="danger" dismissible
                       @dismissed="currentPasswordError=false"
                       show class="custom-alert-ja has-icon-alert"
                       tabindex="0" :aria-label="$t('ad_error_required')">
                <div class="title-alert">{{ $t("ad_error_required") }}</div>
                <div class="alert-type-icon icon-cancel-circled"></div>
              </b-alert>
            </div>
          </div>
        </b-form-group>
        <b-form-group label-for="password_field">
          <b-form-input
            id="password_field"
            v-model="form.password"
            type="password"
            :placeholder='$t("ad_label_new_password")'
            class="common-input"
          ></b-form-input>

          <div>
            <div class="container-alert mt-1" v-if="passwordError">
              <b-alert variant="danger" dismissible
                       @dismissed="passwordError=false"
                       show class="custom-alert-ja has-icon-alert"
                       tabindex="0" :aria-label="$t('ad_error_required')">
                <div class="title-alert">{{ $t("ad_error_required") }}</div>
                <div class="alert-type-icon icon-cancel-circled"></div>
              </b-alert>
            </div>
          </div>
        </b-form-group>
        <b-form-group label-for="confirm_password_field">
          <b-form-input
            id="confirm_password_field"
            v-model="form.confirmPassword"
            type="password"
            :placeholder='$t("ad_label_repeat_password")'
            class="common-input"
          ></b-form-input>
          <div>
            <div class="container-alert mt-1" v-if="confirmPasswordError">
              <b-alert variant="danger" dismissible
                       @dismissed="confirmPasswordError=null"
                       show class="custom-alert-ja has-icon-alert"
                       tabindex="0" :aria-label="confirmPasswordError">
                <div class="title-alert">{{ confirmPasswordError }}</div>
                <div class="alert-type-icon icon-cancel-circled"></div>
              </b-alert>
            </div>
          </div>
        </b-form-group>

        <div class="container-alert" v-if="showSuccessMessage">
          <b-alert variant="success" show class="custom-alert-ja has-icon-alert"
                   tabindex="0" :aria-label="$t('ad_changepassword_success')">
            <div></div>
            <div class="title-alert">
              {{ $t("ad_changepassword_success") }}
            </div>
            <div class="alert-type-icon icon-ok-circled"></div>
          </b-alert>
        </div>

        <div class="container-btns-form mt-4" :class="{'btns-max-width': isLongTextToBtn()}" v-else>
          <b-button type="submit" class="common-btn common-btn-green common-btn-secondary"
                    tabindex="0" :aria-label="$t('ad_button_enter')" id="btnSubmitForm">{{ $t("ad_button_enter") }}</b-button>

          <b-button @click="goToAdminHome"
                    @keypress.prevent.space="goToAdminHome"
                    class="common-btn common-btn-green common-btn-secondary"
                    tabindex="0" :aria-label="$t('ad_button_cancel')" type="button">
            {{ $t("ad_button_cancel") }}
          </b-button>
        </div>

        <div v-if="serviceError">
          <div class="container-alert mt-0" v-if="currentPasswordErrorService">
            <b-alert show variant="danger" dismissible @dismissed="currentPasswordErrorService=false"
                     class="custom-alert-ja has-icon-alert" :aria-label="currentPasswordErrorService" tabindex="0">
              <div class="title-alert">{{ currentPasswordErrorService }}</div>
              <div class="alert-type-icon icon-cancel-circled"></div>
            </b-alert>
          </div>

          <div class="container-alert mt-0" v-if=passwordErrors.length >
            <b-alert show variant="danger" dismissible @dismissed="passwordErrors=[]"
                     class="custom-alert-ja has-icon-alert">
              <div class="title-alert" v-for="(error, index) in passwordErrors" :key="`passErrors_${index}`">
                <div :aria-label="error" tabindex="0">{{ error }}</div>
              </div>
              <div class="alert-type-icon icon-cancel-circled"></div>
            </b-alert>
          </div>
        </div>
      </b-form>
    </template>
  </base-form>
</template>

<script>
  import { Utils } from '@/core/Utils'
  import { UserService } from '../Services';
  import { BaseForm, HeaderForm } from '@/core/Components'

  export default {
    name: "AdminChangePassword",
    components: { BaseForm, HeaderForm },
    data(){
      return {
        form: {
          currentPassword: '',
          password: '',
          confirmPassword: ''
        },
        currentPasswordError: false,
        passwordError: false,
        confirmPasswordError: "",
        currentPasswordErrorService: false,
        passwordErrors: [],
        serviceError: false,
        showSuccessMessage: false
      }
    },
    methods: {
      onSubmit() {
        this.changePassword();
        this.blurElement(null, 'btnSubmitForm')
      },
      changePassword(){
        const form = this.form;

        const currentPassword = form.currentPassword;
        const password = form.password;
        const confirmPassword = form.confirmPassword;
        this.currentPasswordError = currentPassword === "";
        this.passwordError = password === "";
        this.confirmPasswordError = ""

        if(confirmPassword === "") this.confirmPasswordError = this.$t("ad_error_required");
        if(password !== confirmPassword) this.confirmPasswordError =  this.$t("ad_error_confirm_password");
        if(confirmPassword !== "" && password === confirmPassword) this.confirmPasswordError =  "";

        if(currentPassword && password && confirmPassword && password === confirmPassword) {
          this.passwordErrors = []
          this.currentPasswordErrorService = false
          this.serviceError = false

          UserService.changePassword(form).then(() => {
            this.showSuccessMessage = true;
            setTimeout(() => {
              this.goToAdminHome();
            }, 1500)
          }).catch((err) => {
            this.showSuccessMessage = false;
            this.serviceError = true
            if (err) {
              if (err.errors && err.errors['Password']) {
                err.errors['Password'].forEach(error => this.passwordErrors.push(error))
              }
              if (err.errors && err.errors['CurrentPassword']) {
                this.currentPasswordErrorService = this.$t("ad_changepassword_current_password_error")
              }
            }
          })
        }
      },
      isLongTextToBtn(){
        const firstBtn = Utils.isLongTextToBtn(this.$t('ad_button_enter'))
        const secondBtn = Utils.isLongTextToBtn(this.$t('ad_button_cancel'))
        return firstBtn || secondBtn
      }
    }
  };
</script>

<style lang="stylus" scoped>
</style>
