<template>
  <section class="teams-block">
    <div class="common-card pt-3 pb-3 pl-3 pr-3 mb-3 w-auto">
      <div class="pt-3 pb-3">
        <h6 class="custom-title title-h6 mb-1"
            tabindex="0" :aria-label="$t('ad_setupgame_teams')">{{ $t("ad_setupgame_teams") }}</h6>
        <div class="common-text mb-3" tabindex="0" :aria-label="$t('ad_setupgame_teams_description')">
          {{ $t("ad_setupgame_teams_description") }}
        </div>
        <div class="teams-block__container-total-students mb-3">
          <div class="total-students-item mr-4 mb-2" v-if="isIndividualMode"
               tabindex="0" :aria-label="$t('ad_setupgame_number_students') + ' ' + totalStudents">
            <div class="custom-title title-h6 total-label mr-2">{{ $t("ad_setupgame_number_students") }}</div>
            <div class="total-number">{{ totalStudents }}</div>
          </div>
          <div class="total-students-item mb-2" tabindex="0" :aria-label="$t('ad_setupgame_number_teams') + ' ' + totalTeams">
            <div class="custom-title title-h6 total-label mr-2">{{ $t("ad_setupgame_number_teams") }}</div>
            <div class="total-number">{{ totalTeams }}</div>
          </div>
        </div>
        <div class="separator-team-block mt-3 mb-4"></div>

        <div class="teams-block__table-teams" v-if="gameCode && teamsList.length > 0">
          <div class="team-col" :class="{'is-shared-mode': !isIndividualMode}" v-for="(team, index) in teamsList" :key="`team-col-${index}`">
            <div class="team-col__row" tabindex="0"
                 :aria-label="getNumPlayersText(team.students, true, team.teamName)">
              <div class="team-col__row__team-name">{{ team.teamName }}</div>
              <div class="team-col__row__num-students">{{ getNumPlayersText(team.students) }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import { TeacherConstants } from "../Constants"
  import { GameSetup } from "../Services"

  export default {
    name: "TeamsBlock",
    props: {
      gameMode: {
        type: [Number, String],
        required: true,
        default: TeacherConstants.GAME_MODE.INDIVIDUAL.value
      },
      gameId: {
        type: Number,
        required: true,
        default: null
      },
      gameCode: {
        type: String,
        default: null
      }
    },
    data(){
      return {
        teamsList: [],
        teamsListCopy: [],
        totalStudents: 0,
        totalTeams: 0,
        numberOfCols: 3,
        intervalMs: 5000,
        intervalObj: null
      }
    },
    mounted(){
      this.setColumnsSiZe()
      window.addEventListener('resize', () => {
        this.setColumnsSiZe()
      })
      this.getTeamsList()

      setTimeout(()=> {
        this.getListByInterval()
      }, 0);
    },
    destroyed(){
      this.stopGetListByInterval()
    },    
    methods: {
      getSortedTeamsListByGroup(list){
        if(!list || list.length ==0){
          return []
        }

        let newList = JSON.parse(JSON.stringify(list));
        this.totalTeams = newList.length;
        this.totalStudents = 0
        newList = newList.map((item) => {
          this.totalStudents += item.students
          return {...item, ...{teamName: `${this.$t("ad_setupgame_team")} ${item.teamId}`}}
        })

        return newList
      },
      getTeamsList(){
        if(!this.gameCode){
          return
        }
        GameSetup.getTeams(this.gameId).then((response) => {
          const teams = response.teams ? response.teams : []
          this.teamsListCopy = teams
          this.teamsList = this.getSortedTeamsListByGroup(teams)

        }).catch(() => {
          this.teamsList = this.getSortedTeamsListByGroup(this.teamsListCopy)
        })
      },
      getListByInterval(){
        if(this.gameCode){
          this.intervalObj = setInterval(() => {
            this.getTeamsList()
          }, this.intervalMs)
        }
      },
      stopGetListByInterval(){
        if(this.intervalObj){
          clearInterval(this.intervalObj);
        }
      },
      setColumnsSiZe(){
        const wWidth = window.innerWidth
        this.numberOfCols = 3
        if(wWidth <= 850){
          this.numberOfCols = 1
        } else if(wWidth <= 1290){
          this.numberOfCols = 2
        }
      },
      getNumPlayersText(num, isAriaLabel=false, teamName=''){
        let text = '';
        if(isAriaLabel === true){
          text = `${teamName} `
        }

        text += this.$tc("ad_setupgame_num_players", num, {num_players: num});
        return text
      }
    },
    computed: {
      isIndividualMode(){
        return this.gameMode == TeacherConstants.GAME_MODE.INDIVIDUAL.value
      }
    }
  };
</script>

<style lang="stylus" scoped>
  @import "Styles/teams_block.styl";
</style>
