<template>
  <header>
    <b-navbar toggleable="md" variant="faded" type="light" v-if="isTeacherPage">
      <b-navbar-brand :to="{ name: 'TeacherHome' }">
        <img
          src="@/core/Assets/logo-ja-blue-black.svg"
          alt="Junior Achievement"
        />
      </b-navbar-brand>
    </b-navbar>

    <b-navbar toggleable="md" variant="faded" type="light" v-else>
      <b-navbar-brand :to="{ name: 'AdminHome' }">
        <img
          src="@/core/Assets/logo-ja-blue-black.svg"
          alt="Junior Achievement"
        />
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ml-auto">
          <b-nav-item-dropdown right class="nav-item-user-admin">
            <template #button-content>
              <div class="container-name-admin">
                <div class="name-admin-inner">{{ userName }}</div>
              </div>
            </template>
            <!-- <b-dropdown-item-button @click="goToAdminLanguageEditor">
              {{ $t("ad_usermenu_languageeditor") }}
            </b-dropdown-item-button> -->
            <b-dropdown-item-button @click="goToAdminChangePassword">
              {{ $t("ad_usermenu_changepassword") }}
            </b-dropdown-item-button>
            <b-dropdown-item-button @click="logout">
              {{ $t("ad_usermenu_logout") }}
            </b-dropdown-item-button>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </header>
</template>
<script>
import { Storage } from "@/core/Utils";
import { AuthService } from "@/modules/Admin/Security/Services";

export default {
  name: "AppHeader",
  props: {
    isTeacherPage: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      userName: ""
    };
  },
  created() {
    const user = Storage.getAdminUser();
    this.userName = user.email || "";
  },
  methods: {
    logout() {
      AuthService.logout();
      this.goToAdminLogin();
    }
  }
};
</script>

<style lang="stylus" scoped>
@import "styles/app_header.styl";
</style>
