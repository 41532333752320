<template>
  <section>
    <h3 class="custom-title title-h3 text-uppercase mb-3"
        tabindex="0" :aria-label="$t('ad_setupgame_game_setup')">{{ $t("ad_setupgame_game_setup") }}</h3>
    <div class="game-code-card mb-3">
      <div class="common-card w-auto pt-3 pb-3 pl-3 pr-3 game-code-card-inner">
        <h6 class="custom-title title-h6 mr-3 mb-0" tabindex="0" :aria-label="$t('ad_setupgame_code')">{{ $t("ad_setupgame_code") }}</h6>
        <div class="d-flex container-copy-game-code">
          <b-form-group class="mb-0 w-100 mr-2">
            <b-form-input
                id="game_code"
                :value="gameCode"
                type="text"
                placeholder="" readonly
                class="common-input common-input-secondary text-center mb-0 input-code"
                :class="{'input-has-code': teamSetup.gameCode}"
            ></b-form-input>
          </b-form-group>
          <b-button type="button"
                    @click="copyGameCode"
                    ref="btnCopyCode"
                    class="common-btn common-btn-secondary common-btn--smaller btn-game-code text-uppercase"
                    :disabled="teamSetup.gameCode ? false : true"
                    :aria-label="$t('ad_button_copy_aria_label')">{{ $t("ad_button_copy") }}</b-button>
        </div>
      </div>
      <div class="container-alert mt-2 mb-3" v-if="successCopyMessage">
        <b-alert variant="success" show class="custom-alert-ja has-icon-alert"
                 dismissible @dismissed="successCopyMessage=null"
                 tabindex="0" :aria-label="successCopyMessage">
          <div class="title-alert">{{ successCopyMessage }}</div>
          <div class="alert-type-icon icon-ok-circled"></div>
        </b-alert>
      </div>
    </div>

    <teams-block v-if="teamSetup.gameCode"
                 :game-mode="teamSetup.gameMode"
                 :game-code="teamSetup.gameCode"
                 :game-id="teamSetup.gameId"
                 ref="teamsBlock"/>
  </section>
</template>

<script>
  import { TeacherConstants } from "../Constants"
  import TeamsBlock from "./TeamsBlock.vue"

  export default {
    name: "TeamSetUp",
    components: { TeamsBlock },
    props: {
      teamSetup: {
        type: Object,
        required: true,
        default: () => {
          return {
            gameMode: TeacherConstants.GAME_MODE.INDIVIDUAL.value,
            gameCode: null,
            gameId: null
          }
        }
      }
    },
    data(){
      return {
          successCopyMessage: null
      }
    },
    mounted(){},
    methods: {
      startGetTeamsInterval(){
        this.$refs["teamsBlock"].getListByInterval()
      },
      stopGetListByInterval(){
        this.$refs["teamsBlock"].stopGetListByInterval()
      },
      copyGameCode(){
        this.blurElement(this.$refs.btnCopyCode);
        const copyText = document.getElementById("game_code");
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        document.execCommand("copy");

        this.successCopyMessage = this.$t("ad_setupgame_code_copied")
        setTimeout(() => {
          this.successCopyMessage = null
        }, 1000)
      }
    },
    computed: {
      gameCode(){
        return this.teamSetup.gameCode ? this.teamSetup.gameCode : "--------"
      },
    }
  };
</script>

<style lang="stylus" scoped>
  @import "Styles/team_setup.styl";
</style>
