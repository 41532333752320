<template>
  <div class="wrapper-header-steps mb-3">
    <div class="wrapper-steps">
      <step-tab step="1" :step-text='$t("ad_setupgame_setup")' :current-step="currentStep"/>
      <step-tab step="2" :step-text='$t("ad_setupgame_game")' :current-step="currentStep"/>
    </div>
  </div>
</template>

<script>
  import { TeacherConstants } from "../Constants"
  import { StepTab } from '@/core/Components'

  export default {
    name: "HeaderStepsList",
    components: { StepTab },
    props: {
      currentStep: {
        type: [Number, String],
        required: true,
        default: TeacherConstants.INITIAL_STEP
      }
    }
  };
</script>

<style lang="stylus" scoped>
  @import "Styles/header_steps_list.styl";
</style>
