import { EventBus } from './index'

const EVENT_START = 'spinner-start'
const EVENT_STOP = 'spinner-stop'

export default {
    EVENT_START,
    EVENT_STOP,
    start() {
        EventBus.$emit(EVENT_START);
    },
    stop() {
        EventBus.$emit(EVENT_STOP);
    }
}