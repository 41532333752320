import {http} from '@/http'
import {Storage} from '@/core/Utils'

const resource = "/api/game";

export default {
  createGame(payload) {
    return http.post(`${resource}`, payload)
  },
  generateGameCode(gameId, payload) {
    return http.post(`${resource}/${gameId}`, payload)
  },
  startGame(gameCode) {
    return http.put(`${resource}/${gameCode}/startgame/`)
  },
  resetGame(gameCode) {
    return http.put(`${resource}/${gameCode}/resetgame/`)
  },
  getTeams(gameId) {
    return http.post(`${resource}/${gameId}/teams`)
  },
  getGlobalSetup() {
    return http.post(`${resource}/checkconfig`)
  },
  gameResults(gameCode) {
    return http.get(`${resource}/${gameCode}/results/`)
  },
  setStorageGame(game) {
    Storage.setGameSetup(game)
  },
  getStorageGame() {
    return Storage.getGameSetup()
  },
  clearStorageGame(game) {
    Storage.clearGameSetup(game)
  },
}
