<template>
  <b-modal ref="modal"
           centered 
           modal-class="custom-modal custom-modal--not-header custom-modal--not-footer">
    <div class="wrapper-body">

      <h3 class="main-title-modal mb-2 text-center"
          tabindex="0" :aria-label="$t('ad_setupgame_reset_game_title')">{{ $t("ad_setupgame_reset_game_title") }}</h3>
      <div class="text-modal text-center col-md-8 mr-auto ml-auto"
           tabindex="0" :aria-label="$t('ad_setupgame_reset_game_desc')">{{ $t("ad_setupgame_reset_game_desc") }}</div>

      <br>
      <div class="mt-3">
        <b-button @click="resetGame()" class="common-btn common-btn-secondary mr-2"
                  :aria-label="$t('ad_button_yes')">{{ $t("ad_button_yes") }}</b-button>
        <b-button @click="onCancel()" class="common-btn common-btn-secondary"
                  :aria-label="$t('ad_button_no')">{{ $t("ad_button_no") }}</b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
  import { GameSetup } from "@/modules/Teacher/TeacherHome/Services"

  export default {
    name: "ResetGameModal",
    props: {},
    data(){
      return {
      }
    },
    mounted(){},
    methods: {
      showModal(){
        this.$refs['modal'].show()
      },
      hideModal(){
        this.$refs['modal'].hide()
      },
      onCancel(){
        this.hideModal();
      },
      resetGame(){
        const data = {
          privacyPolicy: true,
          termsConditions: true
        }
        GameSetup.createGame(data).then((response) => {
          GameSetup.clearStorageGame();
          GameSetup.setStorageGame(response.gameSetup);
          this.$emit('resetGame')
        }).catch(() => {})
        this.hideModal();
      }
    }
  };
</script>

<style lang="stylus" scoped>
>>> .custom-modal
  .modal-dialog
    max-width 600px
  .wrapper-body
    display flex
    flex-direction column
    justify-content center
    align-items center
    min-height 300px
</style>
