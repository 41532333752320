<template>
  <div class="container-base-form">
    <b-container>
      <b-row align-v="center" class="justify-content-md-center">
        <b-col cols="12" xl="4" lg="5" md="7">
          <slot name="content"></slot>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  export default {
    name: "BaseForm",
  };
</script>

<style lang="stylus" scoped>
</style>
