<template>
  <base-form>
    <template v-slot:content>
      <header-form/>

      <h3 class="title-form-page" tabindex="0"
          :aria-label="$t('ad_forgotpassword_title')">
        {{ $t("ad_forgotpassword_title") }}
      </h3>

      <div class="desc-form-page" tabindex="0"
           :aria-label="$t('ad_forgotpassword_instructions')">
        {{ $t("ad_forgotpassword_instructions") }}
      </div>

      <b-form @submit.prevent="onSubmit" class="mr-auto ml-auto mt-4 w-75">
        <b-form-group label-for="email_field">
          <b-form-input
            id="email_field"
            v-model="form.email"
            type="text"
            :placeholder='$t("ad_label_email")'
            class="common-input"
          ></b-form-input>
          <div>

            <div class="container-alert mt-1" v-if="emailError">
              <b-alert variant="danger" dismissible
                       @dismissed="emailError=false"
                       show class="custom-alert-ja has-icon-alert"
                       tabindex="0" :aria-label="emailError">
                <div class="title-alert">{{ emailError }}</div>
                <div class="alert-type-icon icon-cancel-circled"></div>
              </b-alert>
            </div>
          </div>
        </b-form-group>

        <div class="container-btns-form mt-4"
             :class="{'btns-max-width': isLongTextToBtn() }">
          <b-button type="submit"
                    class="common-btn common-btn-green common-btn-secondary"
                    tabindex="0" :aria-label="$t('ad_button_enter')" id="btnSubmitForm">
            {{ $t("ad_button_enter") }}
          </b-button>

          <b-button @click="goToAdminLogin"
                    @keypress.prevent.space="goToAdminLogin"
                    class="common-btn common-btn-green common-btn-secondary"
                    tabindex="0" :aria-label="$t('ad_button_cancel')">
            {{ $t("ad_button_cancel") }}
          </b-button>
        </div>

        <div class="container-alert" v-if="serviceError && emailErrors.length > 0">
          <b-alert show variant="danger" dismissible @dismissed="emailErrors=[];serviceError=false"
                   class="custom-alert-ja has-icon-alert">

            <div class="title-alert" v-for="(error, index) in emailErrors" :key="`emailErrors_${index}`">
              <div :aria-label="error" tabindex="0">{{ error }}</div>
            </div>
            <div class="alert-type-icon icon-cancel-circled"></div>
          </b-alert>
        </div>
      </b-form>
    </template>
  </base-form>
</template>

<script>
  import { UserService } from '../Services'
  import { Utils } from '@/core/Utils'
  import validateForms from '@/core/Mixins/validate-forms'
  import { BaseForm, HeaderForm } from '@/core/Components'

  export default {
    name: "AdminForgotPassword",
    components: { BaseForm, HeaderForm },
    mixins: [ validateForms ],
    data(){
      return {
        form: {
          email: ''
        },
        emailError: false,
        emailErrors: [],
        serviceError: false,
        hasError: false,
      }
    },
    methods: {
      onSubmit() {
        this.forgot()
        this.blurElement(null, 'btnSubmitForm')
      },
      forgot() {
        const email = this.form.email;
        this.emailError = email === "" ? this.$t("ad_error_required") : false;
        this.hasError = false;
        this.serviceError = null;
        this.emailErrors = [];

        if (email && this.isValidform()) {
          UserService.forgotPasswordRequest(email).then(() => {
            this.goToAdminVerifyEmailPassword( {email} )
          }).catch(err => {
            this.hasError = true;
            this.serviceError = this.$t("ad_error_unexpected")
            if (err) {
              if (err.errors && err.errors['Email']) {
                err.errors['Email'].forEach(error => {
                  this.emailErrors.push(error)
                })
              }
            }
          })
        }
      },
      isLongTextToBtn(){
        const firstBtn = Utils.isLongTextToBtn(this.$t('ad_button_enter'))
        const secondBtn = Utils.isLongTextToBtn(this.$t('ad_button_cancel'))
        return firstBtn || secondBtn
      }
    }
  };
</script>

<style lang="stylus" scoped>
</style>
