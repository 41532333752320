<template>
  <div class="wrapper-inner-general-setup">
    <section>
      <div class="container-general-setup">
        <currency
          v-if="showCurrencySection"
          :currency-data="currencyData"
          :section-id="setupSection.CURRENCY"
        />

        <div class="vertical-separator second-vs"></div>

        <game-toppings
          v-if="showSelectToppingsSection"
          :select-toppings-data="selectToppingsData"
        />
      </div>
    </section>

    <div class="separator mt-4 mb-4"></div>

    <section>
      <terms-and-privacy-policy
        v-if="showTermsPolicySection"
        :terms-data="termsPolicyData"
      />
    </section>
  </div>
</template>

<script>
import { GlobalSetup } from "../Services";
import { Currency, GameToppings, TermsAndPrivacyPolicy } from "../Components";

const SETUP_SECTION = {
  LANGUAGE_TOOL: 1,
  CURRENCY: 2,
  SELECT_TOPPINGS: 3,
  TERMS_MANAGER: 4,
  PRIVACY_POLICY_MANAGER: 5
};

export default {
  name: "AdminHome",
  components: { Currency, GameToppings, TermsAndPrivacyPolicy },
  data() {
    return {
      currencyData: {},
      showCurrencySection: false,
      termsPolicyData: {},
      showTermsPolicySection: false,
      showLanguageSection: false,
      selectToppingsData: {},
      showSelectToppingsSection: false,
      setupSection: SETUP_SECTION
    };
  },
  methods: {
    getGlobalData() {
      GlobalSetup.getGlobalSetup().then(response => {
        this.currencyData = {
          currencySymbol: response.setup.currencySymbol,
          currencySymbolPosition: response.setup.currencySymbolPosition
        };
        this.showCurrencySection = true;

        this.termsPolicyData = {
          terms: {
            active: response.setup.termsActive,
            url: response.setup.termsUrl,
            isTermsManager: true,
            sectionId: this.setupSection.TERMS_MANAGER
          },
          policy: {
            active: response.setup.policyActive,
            url: response.setup.policyUrl,
            isTermsManager: false,
            sectionId: this.setupSection.PRIVACY_POLICY_MANAGER
          }
        };

        this.showTermsPolicySection = true;

        this.showLanguageSection = true;

        this.selectToppingsData = {
          toppingsList: response.toppings ? response.toppings : [],
          selectedToppingIds: response.setup.selectedToppingIds,
          setupSection: this.setupSection.SELECT_TOPPINGS
        };

        this.showSelectToppingsSection = true;
      });
    }
  },
  beforeMount() {
    this.getGlobalData();
  }
};
</script>

<style lang="stylus" scoped>
@import "../Styles/styles.styl";
</style>
