<template>
  <base-form>
    <template v-slot:content>
      <header-form/>

      <h3 class="title-form-page" tabindex="0" :aria-label="$t('ad_verifyemail_title')">{{ $t("ad_verifyemail_title") }}</h3>
      <div class="desc-form-page mb-5" tabindex="0" :aria-label="$t('ad_verifyemail_instructions')">
        {{ $t("ad_verifyemail_instructions") }}
      </div>
      <div class="d-flex justify-content-center">
        <b-button @click="goToAdminLogin"
                  @keypress.prevent.space="goToAdminLogin"
                  class="common-btn common-btn-green text-uppercase"
                  tabindex="0" :aria-label="$t('ad_button_ok')">{{ $t("ad_button_ok") }}</b-button>
      </div>

    </template>
  </base-form>
</template>

<script>
  import { BaseForm, HeaderForm } from '@/core/Components'

  export default {
    name: "AdminVerifyEmailPassword",
    components: { BaseForm, HeaderForm },
  };
</script>

<style lang="stylus" scoped>

</style>
