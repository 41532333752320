import { Bar  } from 'vue-chartjs'

export default {
  name: 'GameResultBar',
  extends: Bar,  
  props: {
    chartdata: {
      type: Object,
      default: null
    },
    options: {
      type: Object,
      default: null
    }
  },
  methods: {
    canvas(){
      const graph = this.$data._chart
      return graph.canvas
    }
  },
  mounted () {
    this.renderChart(this.chartdata, this.options)
  }
}
