<template>
  <b-modal ref="select_toppings_modal"
           centered size="lg" @hidden="resetForm"
           modal-class="custom-modal custom-modal--not-header custom-modal--not-footer">
    <div class="d-flex justify-content-end mb-3">
      <div tabindex="0" :aria-label="$t('ad_close_popup_aria_label')"
           class="btn-close-modal icon-cancel"
           @click="hideModal"
           @keypress.prevent.space="hideModal"
           @keypress.prevent.enter="hideModal"></div>
    </div>

    <div class="pl-2 pr-2">
      <div class="mb-4" tabindex="0"
           :aria-label="$t('ad_dashboard_select_toppings') + ' ' + $t('ad_dashboard_select_toppings_intructions', {num: minSelectedToppings})">
        <h3 class="text-center main-title-modal">{{ $t("ad_dashboard_select_toppings") }}:</h3>
        <div class="text-center common-text w-75 m-auto">
          {{ $t("ad_dashboard_select_toppings_intructions", {num: minSelectedToppings}) }}
        </div>
      </div>

      <b-form @submit.prevent="onSubmit" v-if="showList">
        <div class="container-toppings-list mb-2">
          <b-form-checkbox-group
              id="toppings_list"
              v-model="form.selectedToppings"
              name="flavour-1" tabindex="-1"
          >
            <b-form-checkbox
                class="custom-checkbox-input topping-item"
                v-for="opt in toppings"
                :key="`toppings-opt-${opt.id}`"
                :value="opt.id" :disabled="disabledCheckbox(opt)"
                tabindex="0" :aria-label="$t('ad_select_unselect_option', {optionName: $t(opt.name)})">
              <div class="container-name-topping" tabindex="-1">
                <div class="topping-img" :style="`background-image: url(${opt.imageUrl})`"></div>
                <div class="topping-name">{{ $t(opt.name) }}</div>
              </div>
            </b-form-checkbox>

          </b-form-checkbox-group>
        </div>

        <div class="container-alert" v-if="invalidErrorMessage">
          <b-alert variant="danger" dismissible @dismissed="invalidErrorMessage=null"
                   show class="custom-alert-ja has-icon-alert" tabindex="0" :aria-label="invalidErrorMessage">
            <div class="title-alert">{{ invalidErrorMessage }}</div>
            <div class="alert-type-icon icon-cancel-circled"></div>
          </b-alert>
        </div>

        <div v-if="showValidation">
          <div class="container-alert" v-if="errorMessage">
            <b-alert variant="danger" dismissible @dismissed="errorMessage=null"
                     show class="custom-alert-ja has-icon-alert"
                     tabindex="0" :aria-label="successMessage">
              <div class="title-alert">{{ errorMessage }}</div>
              <div class="alert-type-icon icon-cancel-circled"></div>
            </b-alert>
          </div>

          <div class="container-alert" v-if="successMessage">
            <b-alert variant="success" dismissible @dismissed="successMessage=null"
                     show class="custom-alert-ja has-icon-alert"
                     tabindex="0" :aria-label="successMessage">
              <div class="title-alert">{{ successMessage }}</div>
              <div class="alert-type-icon icon-ok-circled"></div>
            </b-alert>
          </div>
        </div>

        <div v-else class="d-flex justify-content-end mt-5">
          <b-button type="submit" class="common-btn common-btn--small"
                    tabindex="0" :aria-label="$t('ad_button_select')"
                    ref="btnSubmitSelectToppings">{{ $t("ad_button_select") }}</b-button>
        </div>
      </b-form>
    </div>
  </b-modal>
</template>

<script>

  import { GlobalSetup } from "../Services"

  export default {
    name: "SelectToppingsModal",
    props: {
      toppingsData: {
        type: Object,
        required: true,
        default: () => {
          return {
            toppingsList: [],
            setupSection: null,
            selectedToppingIds: null
          }
        }
      },
    },
    data(){
      return {
        form: {
          selectedToppings: [],
        },
        selectedToppingsCopy: [],
        showValidation: false,
        invalidErrorMessage: null,
        errorMessage: null,
        successMessage: null,
        maxSelectedToppings: 4,
        minSelectedToppings: 4,
        showList: false
      }
    },
    mounted(){
      this.form.selectedToppings = this.getSelectedIdsFromStr(this.toppingsData.selectedToppingIds)
      this.selectedToppingsCopy = JSON.parse(JSON.stringify(this.form.selectedToppings))
    },
    methods: {
      showModal(){
        this.$refs['select_toppings_modal'].show()
        this.showList = true
      },
      hideModal(){
        this.$refs['select_toppings_modal'].hide()
      },
      onSubmit(){
        this.saveSelectedToppings()
        this.blurElement(this.$refs.btnSubmitSelectToppings);
      },
      getSelectedIdsFromStr(str){
        if(!str){
          return []
        }
        return str.split(",").map(id => parseInt(id))
      },
      resetFormValidations(){
        this.showValidation = false
        this.errorMessage = null
        this.successMessage = null
        this.invalidErrorMessage = null
      },
      resetForm(){
        this.showList = false
        this.form.selectedToppings = this.selectedToppingsCopy
        this.resetFormValidations()
      },
      isValidForm(){
        this.resetFormValidations()

        if(this.form.selectedToppings.length < this.minSelectedToppings){
          this.invalidErrorMessage = this.$t("ad_dashboard_select_at_least_toppings", { num: this.minSelectedToppings })
          return false
        }

        if(this.form.selectedToppings.length > this.maxSelectedToppings){
          this.invalidErrorMessage = this.$t("ad_dashboard_select_at_most_toppings", { num: this.maxSelectedToppings })
          return false
        }

        return true;
      },
      saveSelectedToppings(){

        if(!this.isValidForm()){
          return
        }
        const dataForm = {
          selectedToppingIds: this.form.selectedToppings,
          setupSection: this.toppingsData.setupSection
        }

        GlobalSetup.setGlobalSetup(dataForm).then(() => {
          this.showValidation = true
          this.successMessage = this.$t("ad_success_update")
          this.selectedToppingsCopy = JSON.parse(JSON.stringify(this.form.selectedToppings))

          setTimeout(() => {
            this.hideModal()
          }, 1000)

        }).catch((err) => {
          this.showValidation = true;
          this.errorMessage = this.$t("ad_error_unexpected")
          if (err) {
            if (err.message) {
              this.errorMessage = err.message
            }
          }
        })
      },
      disabledCheckbox(topping){
        const selectedToppings = JSON.parse(JSON.stringify(this.form.selectedToppings))

        if(selectedToppings.length >= this.maxSelectedToppings){
          const isSelected = selectedToppings.some(el => el == topping.id)
          if(isSelected){
            return false
          }

          return true
        }

        return false
      },
      getSortedToppings(a, b){
        const toppingA = a.name ? a.name : ""
        const toppingB = b.name ? b.name : ""
        return toppingA.localeCompare(toppingB)
      }
    },
    computed: {
      toppings(){
        const toppings = JSON.parse(JSON.stringify(this.toppingsData.toppingsList))
        return toppings.sort(this.getSortedToppings)
      }
    }
  };
</script>

<style lang="stylus" scoped>
  @import "Styles/select_toppings_modal.styl";
</style>
