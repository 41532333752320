<template>
  <page-base :is-teacher-page="true" :inner-page-class="['pt-5']">
    <template v-slot:content>
      <router-view></router-view>
    </template>
  </page-base>
</template>

<script>
  import { PageBase } from '@/core/Components'

  export default {
    name: "TeacherHomeView",
    components: { PageBase },
  };
</script>

<style lang="stylus" scoped>
</style>
