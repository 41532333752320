import AdminView from "./AdminView.vue";
import SecurityRoutes from "./Security/Routes";
import AdminHomeRoutes from "./AdminHome/Routes";
let subRoutes = [];

subRoutes = subRoutes.concat(SecurityRoutes, AdminHomeRoutes)


const mainRoute = [
  {
    path: '/admin',
    component: AdminView,
    children: subRoutes,
  }
]

export default mainRoute;


