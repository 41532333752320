<template>
  <base-form>
    <template v-slot:content>
      <header-form/>
      <b-form @submit.prevent="onSubmit" class="mr-auto ml-auto mt-5 w-75">
        <b-form-group label-for="email_field">
          <b-form-input
            id="email_field"
            v-model="form.email"
            type="text"
            :placeholder='$t("ad_label_email")'
            class="common-input"
          ></b-form-input>

          <div>

            <div class="container-alert mt-1" v-if="emailError">
              <b-alert variant="danger" dismissible
                       @dismissed="emailError=false"
                       show class="custom-alert-ja has-icon-alert"
                       tabindex="0" :aria-label="emailError">
                <div class="title-alert">{{ emailError }}</div>
                <div class="alert-type-icon icon-cancel-circled"></div>
              </b-alert>
            </div>
          </div>
        </b-form-group>

        <b-form-group label-for="password_field">
          <b-form-input
            id="password_field"
            v-model="form.password"
            :placeholder='$t("ad_label_password")'
            type="password"
            class="common-input"
          ></b-form-input>
          <div>
            <div class="container-alert mt-1" v-if="passwordError">
              <b-alert variant="danger" dismissible
                       @dismissed="passwordError=false"
                       show class="custom-alert-ja has-icon-alert"
                       tabindex="0" :aria-label="$t('ad_error_required')">
                <div class="title-alert">{{ $t("ad_error_required") }}</div>
                <div class="alert-type-icon icon-cancel-circled"></div>
              </b-alert>
            </div>

          </div>
        </b-form-group>

        <div class="mb-1 mt-4 text-center">
          <b-button type="submit" class="common-btn common-btn-green m-auto"
                    :aria-label="$t('ad_login_button')" id="btnSubmitForm">{{ $t("ad_login_button") }}</b-button>
        </div>
        <div class="text-center">
          <b-link class="common-link"
                  @click="goToAdminForgotPassword"
                  @keypress.prevent.space="goToAdminForgotPassword">
            {{ $t("ad_login_forgot_password") }}
          </b-link>
        </div>
      </b-form>

      <div class="container-alert container-big-alert" v-if="hasError">
        <b-alert show variant="danger" dismissible @dismissed="hasError=false"
                 class="custom-alert-ja" :aria-label="serviceError" tabindex="0">
          <div class="title-alert">{{ serviceError }}</div>
        </b-alert>
        <div class="container-alert-desc text-center" tabindex="0" :aria-label="serviceErrorDetail">
          {{ serviceErrorDetail }}
        </div>
      </div>

    </template>
  </base-form>
</template>

<script>
  import { AuthService } from '../Services'
  import validateForms from '@/core/Mixins/validate-forms'
  import { BaseForm, HeaderForm } from '@/core/Components'

  export default {
    name: "AdminLogin",
    mixins: [ validateForms ],
    components: { BaseForm, HeaderForm },
    data(){
      return {
        form: {
          email: '',
          password: '',
        },
        emailError: false,
        passwordError: false,
        serviceError: null,
        serviceErrorDetail: false,
        hasError: false,
      }
    },
    methods: {
      onSubmit() {
        this.login();
        this.blurElement(null, 'btnSubmitForm')
      },
      login() {
        const email = this.form.email
        const password = this.form.password
        this.emailError = email === "" ? this.$t("ad_error_required") : false;
        this.passwordError = password === ""
        this.hasError = false;
        this.serviceError = null
        this.serviceErrorDetail = false

        if (email && password && this.isValidform()) {
          AuthService.login(email, password).then(() => {
            this.goToAdminHome()
          }).catch(err => {

            this.hasError = true;
            this.serviceError = this.$t("ad_error_unexpected")
            if (err) {
              if (err.message && err.message === 'Email_No_Confirmed') {
                this.serviceError = this.$t("ad_login_email_no_confirmed")
                this.serviceErrorDetail = ''
              }
              else {
                this.serviceError = this.$t("ad_login_invalid_email")
                this.serviceErrorDetail = this.$t("ad_login_invalid_email_detail")
              }
            }

          })
        }
      },
    }
  };
</script>

<style lang="stylus" scoped>
</style>
