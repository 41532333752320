import {http} from '@/http'

const resource = "/api/auth";
const userResource = "/api/user";

export default {
  forgotPasswordRequest(email) {
    return http.post(`${resource}/forgetpassword/`, {email: email})
  },
  resetPassword(payload) {
    return http.post(`${resource}/resetpassword/`, payload)
  },
  changePassword(payload) {
    return http.post(`${userResource}/changepassword/`, payload)
  },
}
