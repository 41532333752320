<template>
  <div class="container-setup-card container-setup-card--language">
    <div>
      <h4 class="text-uppercase custom-title title-h4 mb-4"
          tabindex="0" :aria-label="$t('ad_dashboard_language_tool')">{{ $t("ad_dashboard_language_tool") }}</h4>
      <b-form @submit.prevent="onSubmit">
        <div class="common-card static-height">
          <div class="text-center">
            <div class="container-card-title mb-1">
              <h6 class="custom-title title-h6"
                  tabindex="0" :aria-label="$t('ad_dashboard_select_language')">{{ $t("ad_dashboard_select_language") }}</h6>
            </div>
            <b-dropdown id="symbol_position" class="custom-dropdown">
              <template #button-content>
                <span class="d-inline-block mr-2">{{ selectedLanguageText }}</span>
              </template>
              <b-dropdown-item-button v-for="value in languageData.languages"
                               :key="`language-opt-${value.languageId}`"
                               @click="updateSelectedLanguage(value)" >
                {{value.cultureDisplayName}}
              </b-dropdown-item-button>

              <b-dropdown-item-button @click="goToNewLanguage">{{ $t("ad_dashboard_add_language") }}</b-dropdown-item-button>
            </b-dropdown>
          </div>

          <div class="container-validation-message"></div>

          <div class="text-center mt-1">
            <b-button type="submit" class="common-btn common-btn-secondary common-btn--small"
                      tabindex="0" :aria-label="$t('ad_button_save')" ref="btnSubmitLanguage">{{ $t("ad_button_save") }}</b-button>
          </div>
        </div>
      </b-form>
      <div class="mt-2" v-if="showValidation">
        <div class="container-alert mt-0" v-if="errorMessage">
          <b-alert variant="danger" dismissible @dismissed="errorMessage=null"
                   show class="custom-alert-ja has-icon-alert" tabindex="0" :aria-label="errorMessage">
            <div class="title-alert">{{ errorMessage }}</div>
            <div class="alert-type-icon icon-cancel-circled"></div>
          </b-alert>
        </div>

        <div class="container-alert mt-0" v-if="successMessage">
          <b-alert variant="success" show dismissible @dismissed="successMessage=null"
                   class="custom-alert-ja has-icon-alert" tabindex="0" :aria-label="successMessage">
            <div class="title-alert">{{ successMessage }}</div>
            <div class="alert-type-icon icon-ok-circled"></div>
          </b-alert>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  import { GlobalSetup } from "../Services"

  export default {
    name: "LanguageTool",
    props: {
      languageData: {
        type: Object,
        required: true,
        default: () => {
          return {
            languages: [],
            defaultLanguageId: null,
          }
        }
      },
    },
    data(){
      return {
        selectedLanguageId: null,
        selectedLanguageText: null,
        showValidation: false,
        errorMessage: null,
        successMessage: null
      }
    },
    mounted(){
      this.selectedLanguageId = this.languageData.defaultLanguageId

      const selectedLanguage = this.getLanguageById(this.selectedLanguageId)
      this.selectedLanguageText = selectedLanguage ? selectedLanguage.cultureDisplayName : this.$t("ad_dashboard_select_language")
    },
    methods: {
      onSubmit(){
        this.saveSelectedLanguage();
        this.blurElement(this.$refs.btnSubmitLanguage)
      },
      updateSelectedLanguage(language){
        this.selectedLanguageId = language.languageId
        this.selectedLanguageText = language.cultureDisplayName
      },
      getLanguageById(languageId){
        const languages = JSON.parse(JSON.stringify(this.languageData.languages));
        const language = languages.find((lng) => {
          return lng["languageId"] == languageId ? lng : null
        });

        return language
      },
      saveSelectedLanguage(){
        if(!this.isValidForm()){
          return
        }

        const dataForm = {
          defaultLanguageId: this.selectedLanguageId,
          setupSection: this.languageData.sectionId
        }

        GlobalSetup.setGlobalSetup(dataForm).then(() => {
          this.showValidation = true
          this.successMessage = this.$t("ad_success_update")

          setTimeout(() => {
            this.showValidation = false
            this.successMessage = null
          }, 1000)

        }).catch((err) => {
          this.showValidation = true;
          this.errorMessage = this.$t("ad_error_unexpected")

          if (err) {
            if (err.message) {
              this.errorMessage = err.message
            }
          }
        })
      },
      isValidForm(){
        this.showValidation = false
        this.errorMessage = null
        this.successMessage = null

        if(!this.selectedLanguageId){
          this.showValidation = true
          this.errorMessage = this.$t("ad_dashboard_error_language")
          return false
        }
        return true
      }
    }
  };
</script>

<style lang="stylus" scoped>
</style>
