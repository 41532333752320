<template>
  <section class="mt-3">
    <br>
    <h3 class="custom-title title-h3 text-uppercase mb-4"
        tabindex="0" :aria-label="$t('ad_gameinprogress_title')">
      {{ $t("ad_gameinprogress_title") }}
    </h3>
    <game-progression v-on="$listeners" @onShowResults="onShowResults"/>

    <div class="separator-section mt-4 mb-4"></div>

    <game-results :results="results" defaultViewMode="value" v-if="showResults"/>
  </section>
</template>

<script>
  import { GameSetup } from '../Services'
  import GameResults from './GameResults.vue'
  import GameProgression from "./GameProgression.vue"

  export default {
    name: "GameStep",
    components: { GameResults, GameProgression },
    props: [],
    data(){
      return {
        results: [],
        showResults: false
      }
    },
    methods: {
      onShowResults(){
        const game = GameSetup.getStorageGame()
        GameSetup.gameResults(game.gameCode).then((response) => {
          this.results = response.results
          this.showResults = true
        }).catch((err) => {
          console.log('Error ', err)
        })
      }
    },
    beforeMount(){}
  };
</script>

<style lang="stylus" scoped>
  .separator-section
    width 100%
    border-top 2px solid #ffffff
</style>
