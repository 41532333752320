import AdminHomeView from "../AdminHomeView.vue"
import AdminHome from "../Pages/AdminHome.vue"

const routes = [
  {
    path: '',
    component: AdminHomeView,
    redirect: { name: 'AdminLogin' },
    children: [
      {
        path: '',
        name: 'AdminHome',
        component: AdminHome,
        meta: { authRequired: true, }
      },
    ]
  }
]

export default routes
