<template>
  <div>
    <b-row class="no-print">
      <b-col>
        <div class="mt-2 mb-4" ref="titleReport">
          <h3 class="custom-title title-h3 text-uppercase mb-2"
              tabindex="0" :aria-label="$t('ad_resultgame_maintitle')">{{ $t("ad_resultgame_maintitle") }}</h3>
        </div>
      </b-col>
    </b-row>
    <b-row class="no-print">
      <b-col class="view-mode-actions mb-3">
        <b-button type="button" @click="changeViewMode($event, 'value')"
                  class="common-btn" :class="{active: isValueMode}"
                  :aria-label="$t('ad_resultgame_seevalues')">
          {{ $t("ad_resultgame_seevalues") }}
        </b-button>
        <b-button type="button" @click="changeViewMode($event, 'graph')"
                  class="common-btn"
                  :class="{active: !isValueMode}"
                  :aria-label="$t('ad_resultgame_seegraph')">
          {{ $t("ad_resultgame_seegraph") }}
        </b-button>
      </b-col>
    </b-row>
    <div class="container-results">
      <b-row class="no-print">
        <b-col class="actions">
          <span
            @click="fullScreen"
            @keypress.prevent.space="fullScreen"
            @keypress.prevent.enter="fullScreen"
            class="common-btn common-btn-secondary btn-full-screen mb-2"
            tabindex="0" id="btnFullScreen"
            :aria-label="$t('ad_resultgame_fullsreen_btn_aria_label')">
          </span>
          <button type="button"
              @click="save" id="btnSaveView"
              class="common-btn common-btn-secondary mb-2" :aria-label="$t('ad_button_save')">
            {{ $t("ad_button_save") }}
          </button>
          <button type="button"
              @click="print" id="btnPrintView"
              class="common-btn common-btn-secondary mb-2" :aria-label="$t('ad_resultgame_printresults')">
            {{ $t("ad_resultgame_printresults") }}
          </button>
        </b-col>
      </b-row>
      <b-row cols="1" cols-md="2" cols-lg="3" cols-xl="5" ref="valueReport" class="card-results mt-1" v-if="isValueMode">
        <b-col v-for="(result, index) in results" :key="index" class="team-result">
          <b-card 
          border-variant="light" :header="teamTitle(result.teamId)"
          class="text-center result-card" tabindex="0" :aria-label="teamTitle(result.teamId)">
            <b-card-text>
              <p tabindex="0" :aria-label="$t('ad_resultgame_totalpizzas') + ' ' + result.totalCompleted">
                {{ $t("ad_resultgame_totalpizzas") }}: <strong>{{ result.totalCompleted }}</strong>
              </p>
              <p tabindex="0" :aria-label="$t('ad_resultgame_totalremakes') + ' ' + result.totalRemake">
                {{ $t("ad_resultgame_totalremakes") }}: <strong>{{ result.totalRemake }}</strong>
              </p>
              <p class="earned-label" tabindex="0" :aria-label="$t('ad_resultgame_totalearned') + ' ' + result.totalEarned">
                {{ $t("ad_resultgame_totalearned") }}:
              </p>
              <strong> {{ getTotalEarned(result.totalEarned) | customCurrency }} </strong>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
      <br>
      <b-row v-if="!isValueMode">
        <b-col class="graph-result" col cols="12" tabindex="0" :aria-label="$t('ad_resultgame_graphcart_aria_label')"
               v-for="(result, index) in resultsToGraph" :key="index">
          <game-result-bar :ref="'graph_'+ index" :chartdata="result.data" :options="result.options" :height="400" :width="1180" />
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import { Utils } from '@/core/Utils'
import GameResultBar from './GameResultBar.js'
import { Chart } from 'chart.js'

export default {
  name: "GameResults",
  components: { GameResultBar },
  props: {
    results: {
      type: Array,
      required: true
    },
    defaultViewMode: {
      type: String,
      required: false,
      default: "value"
    }
  },
  data() {
    return {
      viewMode: this.defaultViewMode
    }
  },
  methods:{
    teamTitle(teamId){
      return this.$t("ad_resultgame_teamtitle", {teamId})
    },
    changeViewMode(e, viewMode){
      this.blurElement(e.target);
      this.viewMode = viewMode
    },
    getBase64Graphs(customWidth, customHeight){
      const heightConstant = 100
      const graphCount = this.graphCount
      const canvases = []
      for(let index=0; index < graphCount; index++){
        canvases.push(this.$refs["graph_"+index][0].canvas())
      }
      let graphWidth = canvases[0].width - 20
      let graphHeight = canvases[0].height
      let totalCustomWidth = canvases[0].width
      let totalCustomHeight = canvases[0].height * graphCount + (graphCount * heightConstant)
      
      if(customWidth) {
        graphWidth = customWidth - 20
        totalCustomWidth = customWidth      
      }
      if(customHeight) {
        graphHeight = customHeight
        totalCustomHeight = customHeight * graphCount + (graphCount * heightConstant)
      }

      const canvas = document.createElement("CANVAS")
      canvas.width = totalCustomWidth
      canvas.height = totalCustomHeight
      let ctx1 = canvas.getContext("2d")

      ctx1.fillStyle = "white";
      ctx1.fillRect(0, 0, canvas.width, canvas.height);

      ctx1.font = "30px "+ this.fontCherokeeForGraph;
      ctx1.fillStyle = "#414245";
      ctx1.textAlign = "center";
      ctx1.fillText(this.$t('ad_resultgame_maintitle'), canvas.width/2, 30);

      for(let index=0; index < graphCount; index++){
        var ypos = graphHeight * index + ((index>0) ? (index * heightConstant) : 50)
        ctx1.drawImage(canvases[index], 0, ypos, graphWidth, graphHeight);
      }

      return canvas.toDataURL("image/png", 1.0);
    },
    async getBase64ViewMode(customWidth){
      const el = this.$refs.valueReport
      const canvas = document.createElement("CANVAS")
      let reportWidth = el.offsetWidth
      if(customWidth){
        reportWidth = customWidth
      }
      canvas.width = reportWidth
      canvas.height = el.offsetHeight + 45
      let ctx = canvas.getContext("2d")
      ctx.fillStyle = "white"
      ctx.fillRect(0, 0, reportWidth, canvas.height)
      const reportData = await this.$html2canvas(el)      
      // title
      ctx.font = "30px "+ this.fontCherokeeForGraph;
      ctx.fillStyle = "#414245";
      ctx.textAlign = "center";
      ctx.fillText(this.$t('ad_resultgame_maintitle'), reportWidth/2, 30)

      ctx.drawImage(reportData, 0, 40, reportWidth, el.offsetHeight)
      return canvas.toDataURL("image/png");
    },
    async fullScreen(){
      this.blurElement(null, 'btnFullScreen');

      let reportTitle = this.$t("ad_resultgame_maintitle")
      let reportData = null
      if(!this.isValueMode){
        reportData = this.getBase64Graphs()
      }else{
        reportData = await this.getBase64ViewMode()
      }
      const pwin = window.open("","_blank");
      const img = pwin.document.createElement("IMG")
      img.src = reportData
      if(reportTitle) {
        pwin.document.title = reportTitle
      }
      pwin.document.body.appendChild(img)
    },
    async print(){
      this.blurElement(null, 'btnPrintView');

      let reportData = null
      if(!this.isValueMode){
        reportData = this.getBase64Graphs(960, 325)
      }else{
        reportData = await this.getBase64ViewMode(960)
      }
      const pwin = window.open("","_blank");
      const img = pwin.document.createElement("IMG")
      img.src = reportData
      img.addEventListener('load', function(){
        pwin.focus()
        pwin.print()
        pwin.document.close()
      })
      pwin.document.body.appendChild(img)
    },
    async save(){
      this.blurElement(null, 'btnSaveView');

      let reportData = null
      if(!this.isValueMode){
        reportData = this.getBase64Graphs()
      }else{
        reportData = await this.getBase64ViewMode()
      }
      const link = document.createElement('a')
      link.href = reportData
      link.setAttribute('download', "report.png")
      link.click()
    },
    getGraphTeamLabels(teamResults){
      return teamResults.map( (result) => {
        return [`${this.$t("ad_resultgame_correct")}`,
                `${this.$t("ad_resultgame_remakes")}`, 
                this.teamTitle(result.teamId).toUpperCase(), 
                `${this.$t("ad_resultgame_totalearned")}:`, 
                this.$options.filters.customCurrency(result.totalEarned)]
      })
    },
    getGraphData(items){
      return {
        labels: this.getGraphTeamLabels(items),
        datasets: [{
          categoryPercentage: 0.6,
          barPercentage: 0.7,
          label: "",
          backgroundColor: "#008751",
          data: items.map((result) => { return result.totalCompleted }),
        }, {
          categoryPercentage: 0.6,
          barPercentage: 0.7,
          label: "",
          backgroundColor: "#D2242A",
          data: items.map((result) => { return result.totalRemake })
        }]
      }
    },
    getGraphOptions(maxValue){
      return {
        plugins: {
          updateLabelsInGraph: {
            fontCherokee: this.fontCherokeeForGraph,
            fontOpensans: this.fontOpenSansForGraph,
            fontSizeAxisY: this.fontSizeForAxisY
          },
        },
        responsive: false,
        maintainAspectRatio: true,
        barValueSpacing: 20,
        tooltips: {
          enabled: false
        },
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            ticks: {
              fontSize: 15,
              fontFamily: this.fontCherokeeForGraph,
              callback: function(value) {
                // hack to ensure the same space for the label lines
                return value.map( () => '' );
              }
            }
          }],
          yAxes: [{
            ticks: {
              min: 0,
              max: maxValue + 1,
              fontStyle: 'bold',
              color: "#414245",
              fontSize: this.fontSizeForAxisY,
              fontFamily: this.fontCherokeeForGraph,
            },
          }],
          
        },
        animation: false,
        hover: {
          mode: null,
          animationDuration: null,
          intersect: false
        },
        events: [],
        responsiveAnimationDuration: false,
      }
    },
    addPlugin(){
      var plugin = {
        id: 'updateLabelsInGraph',

        afterRender: function(chart, options) {
          var teamItemPositions = {}
          var ctx = chart.ctx
          ctx.fillStyle = "#414245"
          ctx.font = `bold ${options.fontSizeAxisY}px ${options.fontCherokee}`
          // Set value (pizzas or remakes count) as label of the bars
          const positionBasedOnNumberLength = [0, 6, 3]
          chart.data.datasets.forEach(function(dataset, i) {
            var meta = chart.controller.getDatasetMeta(i)
            meta.data.forEach(function(bar, index) {
              var data = dataset.data[index]
              ctx.fillText(data, bar._model.x - positionBasedOnNumberLength[`${data}`.length - 1], bar._model.y - 5)
              if(!teamItemPositions[index]) {
                teamItemPositions[index] = {
                  x: bar._model.x - 20,
                  labels: bar._model.label,
                  teamCount: dataset.data.length - 1 
                }
              }
            });
          });
          
          // Transform the souce label (list of strings) to Canvas Text. They need some calculations (x,y) to position the labels correctly.
          // Also, this flow set custom font and size for each line of the label.
          var fontSizes = [options.fontSizeAxisY, options.fontSizeAxisY, 16, 12, 16]
          var fontStyles = ['', '', 'bold', '', 'bold']
          var fontsForGraph = [options.fontOpensans, options.fontOpensans, options.fontOpensans, 
                              options.fontOpensans, options.fontCherokee ]
          var remakePositions = [340, 170, 110, 80, 65]
          var teamLabelXPosConstant = [180, 80, 50, 35, 30]

          Object.keys(teamItemPositions).forEach(function(teamId) {
            var labelData = teamItemPositions[teamId]
            labelData.labels.forEach(function(label, i) {
              ctx.font = `${fontStyles[i]} ${fontSizes[i]}px ${fontsForGraph[i]}` 
              // set letter spacing
              if(i==1){
                chart.canvas.style.letterSpacing = '2.4px';
              }else{
                chart.canvas.style.letterSpacing = '0px';
              }
              // set the text with font
              if(i<=1) { // first line label 0 and 1
                ctx.fillText(label, labelData.x + (i * remakePositions[labelData.teamCount]), 310)
              }else{
                ctx.fillText(label, labelData.x + teamLabelXPosConstant[labelData.teamCount], 310 + (i * 20) )
              }
              
            })
          })
        }
      }
      Chart.plugins.register(plugin);
    },
    getTotalEarned(total){
      if(!total){
        return "0"
      }

      return total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
  },
  computed:{
    isValueMode(){
      return this.viewMode === "value"
    },
    resultsToGraph(){
      let graphResults = []
      const teamPerGraph = 5;
      let teamMemberCounter = 0;
      const completedValueList = this.results.map((result) => {return result.totalCompleted})
      const remakesValueList = this.results.map((result) => {return result.totalRemake})
      const maxValue = Math.max(...completedValueList, ...remakesValueList)
      while(teamMemberCounter < this.results.length){
        const items= this.results.slice(teamMemberCounter, teamMemberCounter+teamPerGraph)
        graphResults.push({
          data: this.getGraphData(items),
          options: this.getGraphOptions(maxValue)
        })
        teamMemberCounter += teamPerGraph
      }
      return graphResults
    },
    graphCount(){
      return this.resultsToGraph.length 
    },
    fontCherokeeForGraph(){
      return "phoreuscherokee"
    },
    fontOpenSansForGraph(){
      return "'Open Sans', sans-serif"
    },
    fontSizeForAxisY(){
      return 12
    }
  },
  filters: {
    customCurrency: function(value){
      return Utils.formatCurrency(value)
    }
  },
  mounted(){
    this.addPlugin()
  }
}
</script>
<style lang="stylus" scoped>
  @import "Styles/game_results.styl";
</style>