<template>
  <div>
    <b-navbar variant="faded" type="light" class="pt-4 pl-4">
      <b-navbar-brand :to="{name: 'TeacherLogin'}" class="pt-3 pl-3">
        <img src="@/core/Assets/logo-ja-blue-black.svg" class="logo-ja" alt="Junior Achievement">
      </b-navbar-brand>
    </b-navbar>

    <base-form>
      <template v-slot:content>
        <header-form :is-teacher-form="true"/>
        <b-form @submit.prevent="onSubmit" class="mt-4 mb-4 mr-auto ml-auto col-md-10">
          <div class="desc-login-page text-center mb-4" v-html="$t('ad_teacher_review_terms')" tabindex="0"></div>

          <div class="mb-4">
            <div class="d-flex justify-content-center" v-if="showTermAndPolicy && globalSetup.termsActive">
              <b-form-group>
                <b-form-checkbox
                    class="custom-checkbox-input custom-checkbox-green checkbox-teacher-login"
                    v-model="form.termsConditions" tabindex="0"
                    :aria-label="$t('ad_select_unselect_option', {optionName: $t('ad_teacher_accept_terms')})">
                  <div class="">
                    <div class="text-checkbox">{{ $t("ad_teacher_accept_terms") }}</div>
                    <b-link class="link-checkbox" :href="globalSetup.termsUrl" target="_blank"
                            tabindex="0" :aria-label="$t('ad_teacher_view_terms')">{{ $t("ad_teacher_view_terms") }}</b-link>
                  </div>
                </b-form-checkbox>
              </b-form-group>
            </div>

            <div class="d-flex justify-content-center" v-if="showTermAndPolicy && globalSetup.policyActive">
              <b-form-group>
                <b-form-checkbox
                    class="custom-checkbox-input custom-checkbox-green checkbox-teacher-login"
                    v-model="form.privacyPolicy" tabindex="0"
                    :aria-label="$t('ad_select_unselect_option', {optionName: $t('ad_teacher_accept_policy')})">
                  <div class="">
                    <div class="text-checkbox">{{ $t("ad_teacher_accept_policy") }}</div>
                    <b-link class="link-checkbox" :href="globalSetup.policyUrl" target="_blank"
                            tabindex="0" :aria-label="$t('ad_teacher_view_policy')">{{ $t("ad_teacher_view_policy") }}</b-link>
                  </div>
                </b-form-checkbox>
              </b-form-group>
            </div>
          </div>

          <div class="mb-4" v-if="errorMessage || successMessage">
            <div class="container-alert" v-if="errorMessage">
              <b-alert variant="danger" dismissible @dismissed="errorMessage=null"
                       show class="custom-alert-ja has-icon-alert"
                       tabindex="0" :aria-label="errorMessage">
                <div class="title-alert">{{ errorMessage }}</div>
                <div class="alert-type-icon icon-cancel-circled"></div>
              </b-alert>
            </div>

            <div class="container-alert" v-if="successMessage">
              <b-alert variant="success" show dismissible @dismissed="successMessage=null"
                       class="custom-alert-ja has-icon-alert" tabindex="0" :aria-label="successMessage">
                <div></div>
                <div class="title-alert">
                  {{ successMessage }}
                </div>
                <div class="alert-type-icon icon-ok-circled"></div>
              </b-alert>
            </div>
          </div>

          <div class="text-center">
            <b-button type="submit" class="common-btn common-btn-green m-auto"
                      :disabled="disabledBtn" tabindex="0"
                      :aria-label="$t('ad_teacher_create_game')" ref="btnSubmitCreateGame">
              {{ $t("ad_teacher_create_game") }}
            </b-button>
          </div>
        </b-form>
      </template>
    </base-form>
  </div>
</template>

<script>

  import { GameSetup } from "@/modules/Teacher/TeacherHome/Services"
  import { BaseForm, HeaderForm } from '@/core/Components'
  import { Storage } from '@/core/Utils'

  export default {
    name: "TeacherLogin",
    components: { BaseForm, HeaderForm },
    data(){
      return {
        form: {
          termsConditions: false,
          privacyPolicy: false
        },
        errorMessage: null,
        successMessage: null,
        disabledBtn: false,
        showTermAndPolicy: true,
        globalSetup: {}
      }
    },
    beforeMount(){
      this.checkConfig();
    },
    methods: {
      onSubmit() {
        this.createGame();
        this.blurElement(this.$refs.btnSubmitCreateGame);
      },
      checkConfig(){
        GameSetup.getGlobalSetup().then((response) => {
          this.globalSetup = response.globalSetup
          this.disabledBtn = false
          this.showTermAndPolicy = true
          Storage.setGameGlobalSetup(this.globalSetup)

        }).catch(() => {
          this.disabledBtn = true
          this.showTermAndPolicy = false
        })
      },
      createGame(){
        this.disabledBtn = true

        if(!this.isValidForm()){
          this.disabledBtn = false
          return
        }

        const dataForm = {
          privacyPolicy: this.form.privacyPolicy,
          termsConditions: this.form.termsConditions
        }

        GameSetup.createGame(dataForm).then((response) => {
          GameSetup.clearStorageGame();
          GameSetup.setStorageGame(response.gameSetup);

          this.successMessage = this.$t("ad_teacher_sucess_create_game")
          setTimeout(() => {
            this.goToTeacherHome({fromLoginPage: true})
          }, 1000)

        }).catch((err) => {
          this.disabledBtn = false
          this.errorMessage = this.$t("ad_error_unexpected")

          if (err) {
            if (err.message) {
              this.errorMessage = err.message
            }
          }
        })
      },
      isValidForm(){
        this.successMessage = null
        this.errorMessage = null

        if(this.globalSetup.termsActive && !this.form.termsConditions){
          this.errorMessage = this.$t("ad_teacher_error_terms")
          return false
        }

        if(this.globalSetup.policyActive && !this.form.privacyPolicy){
          this.errorMessage = this.$t("ad_teacher_error_policy")
          return false
        }

        return true
      }
    },
  };
</script>

<style lang="stylus" scoped>
  @import "../Styles/styles.styl";
</style>
