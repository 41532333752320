<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>

  export default {
    watch:  {
      $route: () => {
        window.scrollTo(0,0);
      }
    }
  }

</script>
