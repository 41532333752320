<template>
  <div class="container-setup-card container-setup-card--currency">
    <div>
      <h4 class="text-uppercase custom-title title-h4 mb-4"
          tabindex="0" :aria-label="$t('ad_dashboard_currency')">{{ $t("ad_dashboard_currency") }}</h4>
      <b-form @submit.prevent="onSubmit">
        <div class="common-card static-height w-auto">
          <div class="container-currency-actions">
            <div class="currency-action-inner currency-action text-center">

              <div class="container-card-title mb-1">
                <h6 class="custom-title title-h6 mb-0"
                    tabindex="0" :aria-label="$t('ad_dashboard_currency_title')">{{ $t("ad_dashboard_currency_title") }}</h6>
                <div class="smallest-text text-center"
                     tabindex="0" :aria-label="$t('ad_dashboard_type_currency')">{{ $t("ad_dashboard_type_currency") }}</div>
              </div>

              <b-form-group class="mb-3">
                <b-form-input
                  id="currency"
                  v-model="form.currencySymbol"
                  type="text"
                  placeholder=""
                  maxlength="10"
                  class="common-input common-input-secondary text-center mb-0"
                ></b-form-input>
              </b-form-group>
            </div>

            <div class="currency-action-inner symbol-action text-center">
              <div class="container-card-title mb-1">
                <h6 class="custom-title title-h6"
                    tabindex="0" :aria-label="$t('ad_dashboard_symbol_position')">{{ $t("ad_dashboard_symbol_position") }}</h6>
              </div>

              <b-dropdown id="symbol_position" class="custom-dropdown">
                <template #button-content>
                  <span class="d-inline-block mr-2">{{ form.currencySymbolPosition === null ? $t("ad_dashboard_select_option") : form.currencySymbolPosition }}</span>
                </template>
                <b-dropdown-item-button v-for="(opt, key) in symbolPositionOptions"
                                 :key="`symbol-position-opt-${opt.id}`"
                                 @click="updateFormSymbolPosition(key)" >
                  {{opt.text}}
                </b-dropdown-item-button>
              </b-dropdown>
            </div>
          </div>
          <div class="container-validation-message is-currency"></div>
          <div class="text-right">
            <b-button type="submit" class="common-btn common-btn-secondary common-btn--small"
                      tabindex="0" :aria-label="$t('ad_button_save')" ref="btnSubmitCurrency">{{ $t("ad_button_save") }}</b-button>
          </div>
        </div>
        <div class="mt-2" v-if="showValidation">
          <div class="container-alert mt-0" v-if="errorMessage">
            <b-alert variant="danger" dismissible @dismissed="errorMessage=null"
                     show class="custom-alert-ja has-icon-alert" tabindex="0" :aria-label="errorMessage">
              <div class="title-alert">{{ errorMessage }}</div>
              <div class="alert-type-icon icon-cancel-circled"></div>
            </b-alert>
          </div>

          <div class="container-alert mt-0" v-if="successMessage">
            <b-alert variant="success" show dismissible @dismissed="successMessage=null"
                     class="custom-alert-ja has-icon-alert" tabindex="0" :aria-label="successMessage">
              <div class="title-alert">{{ successMessage }}</div>
              <div class="alert-type-icon icon-ok-circled"></div>
            </b-alert>
          </div>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>

  import { GlobalSetup } from "../Services"

  const SYMBOL_POSITION_OPTS = {
    "Left": { value: 'Left', text: 'Left', id: 0 },
    "Right": { value: 'Right', text: 'Right', id: 1 }
  }

  export default {
    name: "Currency",
    props: {
      currencyData: {
        type: Object,
        required: true,
        default: () => {
          return {
            currencySymbol: "",
            currencySymbolPosition: null
          }
        }
      },
      sectionId: {
        type: Number,
        required: true
      }
    },
    data(){
      return {
        form: {
          currencySymbol: "",
          currencySymbolPosition: null
        },
        symbolPositionOptions: SYMBOL_POSITION_OPTS,
        showValidation: false,
        errorMessage: null,
        successMessage: null
      }
    },
    mounted(){
      this.form = this.currencyData
    },
    methods: {
      onSubmit(){
        this.saveCurrencyData();
        this.blurElement(this.$refs.btnSubmitCurrency);
      },
      updateFormSymbolPosition(key){
        this.form.currencySymbolPosition = key
      },
      saveCurrencyData(){

        if(!this.isValidForm()){
          return
        }

        const symbolPosition = this.form.currencySymbolPosition
        const dataForm = {
          currencySymbol: this.form.currencySymbol,
          currencySymbolPosition: this.symbolPositionOptions[symbolPosition].value,
          setupSection: this.sectionId
        }
        GlobalSetup.setGlobalSetup(dataForm).then(() => {
          this.showValidation = true
          this.successMessage = this.$t("ad_success_update")

          setTimeout(() => {
            this.showValidation = false
            this.successMessage = null
          }, 1000)

        }).catch((err) => {
          this.showValidation = true;
          this.errorMessage = this.$t("ad_error_unexpected")
          if (err) {
            if (err.message) {
              this.errorMessage = err.message
            }
          }
        })
      },
      isValidForm(){
        this.showValidation = false
        this.errorMessage = null
        this.successMessage = null

        if(!this.form.currencySymbol || this.form.currencySymbolPosition === null){
          this.showValidation = true
          this.errorMessage = this.$t("ad_dashboard_error_currency")
          return false
        }
        if(this.form.currencySymbol.length > 10){
          this.showValidation = true
          this.errorMessage = this.$t("ad_dashboard_error_currency_length")
          return false
        }

        return true;
      }
    }
  };
</script>

<style lang="stylus" scoped>
</style>
