<template>
    <div class="spinner-overlay" v-if="show">
        <div class="spinner-gif"></div>
    </div>
</template>

<script>
    import { EventBus, Spinner } from '../Utils'

    export default {
        name: "Spinner",
        data() {
            return {
                show: false
            }
        },
        created() {
            EventBus.$on(Spinner.EVENT_START, () => {
                this.show = true
            })
            EventBus.$on(Spinner.EVENT_STOP, () => {
                this.show = false
            })
        }
    }
</script>

<style scoped lang="stylus">
    .spinner-overlay
        position fixed
        height 100%
        width 100%
        background-color #00000057
        z-index 9999999999

    .spinner-gif
        background url("../Assets/spinner.gif") no-repeat
        background-size 100%
        height 100px
        width 100px
        display inline-block
        position absolute
        left calc(50% - 50px)
        top calc(50% - 50px)
</style>