<template>
  <div class="">
    <div>
      <header-steps-list :current-step="currentStep" class="no-print"/>

      <game-set-up-step
        ref="gameSetUpStep"
        :global-data="globalData"
        v-if="currentStep == stepTabs.gameSetUp"
        @updateCurrentStep="updateCurrentStep"
        @gameStart="onGameStarted"
        :key="componentKey"/>

      <game-step v-if="currentStep == stepTabs.game" @updateCurrentStep="updateCurrentStep"/>
    </div>
  </div>
</template>

<script>

  import { GameSetup } from "../Services"
  import { TeacherConstants } from "../Constants"
  import { GameSetUpStep, GameStep, HeaderStepsList } from "../Components"

  export default {
    name: "TeacherHome",
    components: { GameSetUpStep, GameStep, HeaderStepsList },
    props: ['fromLoginPage'],
    data(){
      return {
        globalData: {},
        stepTabs: {
          gameSetUp: 1,
          game: 2
        },
        currentStep: 1,
        componentKey: 0
      }
    },
    methods: {
      checkConfig(){
        GameSetup.getGlobalSetup().then((response) => {
          this.globalData = response.globalSetup
        }).catch(() => {
          this.goToTeacherLogin()
        })
      },
      updateCurrentStep(stepName){
        this.componentKey += 1
        this.currentStep = this.stepTabs[stepName]
      },
      onGameStarted({ gameCode, gameStatus, gameStartedAt }) {
        let game = GameSetup.getStorageGame()
        if (game.gameCode !== gameCode) return
        if (game.status == TeacherConstants.GAME_STATUS.IN_PROCESS) return

        game.status = gameStatus
        game.startedAt = gameStartedAt
        GameSetup.setStorageGame(game)
        this.updateCurrentStep('game')
      },
    },
    beforeMount(){
      this.checkConfig()
      const game = GameSetup.getStorageGame()
      if(game && game.status == TeacherConstants.GAME_STATUS.IN_PROCESS) {
          this.currentStep = this.stepTabs.game
      }
    },
    beforeRouteLeave (to, from, next) {
      this.$refs["gameSetUpStep"].clearInterval();
      next();
    }
  };
</script>
