import {httpLocalization} from '@/httpLocalization'

const getLanguages =  () => {
  return httpLocalization.get(`/api/language/`).then(response => {
  
    return response.data.map(l => {
      return {
        languageId: l.id,
        cultureName: l.culture_name,
        cultureDisplayName: l.culture_display_name,
        suggested: l.suggested
      }
    })
  })
}

const getTranslations = (lng) => {
  return httpLocalization.get(`/api/translation/?culture_name=${lng}`)
  .then(response => response.data)
}

const saveTranslations = (languageId, payload) => {
  return httpLocalization.post(`/api/translations/?language_id=${languageId}`, payload)
}

export default {
  getTranslations,
  getLanguages,
  saveTranslations
}
