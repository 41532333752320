export default {
  methods: {
    blurElement($target=null, $targetId=null){
      if( $targetId ){
        const $elemt = document.getElementById($targetId)
        if ( $elemt ) $elemt.blur()

      }else if( $target ){
        $target.blur()
      }
    }
  }
}
