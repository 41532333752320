import TeacherView from "./TeacherView.vue";
import TeacherLoginRoutes from "./TeacherLogin/Routes";
import TeacherHomeRoutes from "./TeacherHome/Routes";

let subRoutes = [];
subRoutes = subRoutes.concat(TeacherLoginRoutes, TeacherHomeRoutes)

const mainRoute = [
  {
    path: '/',
    component: TeacherView,
    children: subRoutes,
  }
]

export default mainRoute;


