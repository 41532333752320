import Vue from 'vue'
import VueI18n from "vue-i18n";
import { TranslationsService } from "@/core/Services"

const loadMessages = async () => {
  const lang = navigator.language.split('-')[0] || "en"

  const messages = await TranslationsService.getTranslations(lang)

  return {
    lang,
    messages
  } 
}


function initI18n() {
  Vue.use(VueI18n)

  return loadMessages().then(({lang, messages}) => {
    return new VueI18n({
      locale: lang,
      fallbackLocale: lang,
      messages: {[lang]: messages}
    })
  })
}

export default {
  initI18n,
  loadMessages
}
