<template>
  <div class="section-terms-privacy-policy">
    <h4 class="text-uppercase custom-title title-h4 mb-4"
        tabindex="0" :aria-label="$t('ad_dashboard_terms_policy_manager')">{{ $t("ad_dashboard_terms_policy_manager") }}</h4>

    <div class="mb-4">
      <terms-privacy-policy-item v-if="showItem" :form-data="termsData.terms" :label='$t("ad_dashboard_terms_url")' />
    </div>
    <div>
      <terms-privacy-policy-item v-if="showItem" :form-data="termsData.policy" :label='$t("ad_dashboard_policy_url")'/>
    </div>
  </div>
</template>

<script>

  import TermsPrivacyPolicyItem from "./TermsPrivacyPolicyItem.vue"
  export default {
    name: "TermsAndPrivacyPolicy",
    components: { TermsPrivacyPolicyItem },
    props: {
      termsData: {
        type: Object,
        required: true,
        default: null
      }
    },
    data(){
      return {
        showItem: false
      }
    },
    mounted(){
      if(this.termsData !== null){
        this.showItem = true
      }
    }
  };
</script>

<style lang="stylus" scoped>
  @import "Styles/terms_privacy_policy.styl";
</style>
