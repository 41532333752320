<template>
  <router-view></router-view>
</template>

<script>
  export default {
    name: "TeacherView",
    title: 'Plenty of Pizza Game Setup',
  };
</script>

<style lang="stylus" scoped>
</style>
