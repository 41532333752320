import axios from 'axios'

const {
  VUE_APP_JA_LOCALIZATION_URL: JA_LOCALIZATION_URL, 
  VUE_APP_JA_LOCALIZATION_API_KEY:LOCALIZATION_API_KEY 
} = process.env;

export const httpLocalization = axios.create({
  baseURL: JA_LOCALIZATION_URL,
  headers: {
    common: {
      'API_KEY': LOCALIZATION_API_KEY,
      'Content-Type': "application/json",
    }
  }
});