export default {
  methods: {
    goToAdminHome(){
      this.$router.push({name: 'AdminHome'})
    },
    goToAdminLogin(){
      this.$router.push({name: 'AdminLogin'})
    },
    goToAdminChangePassword(){
      this.$router.push({name: 'AdminChangePassword'})
    },
    goToAdminForgotPassword(){
      this.$router.push({name: 'AdminForgotPassword'})
    },
    goToAdminVerifyEmailPassword(params){
      this.$router.push({name: 'AdminVerifyEmailPassword', params: params})
    },
    goToAdminLanguageEditor(){
      this.$router.push({name: 'LanguageSelector'})
    },
    goToNewLanguage(){
      this.$router.push({name: 'NewLanguage'})
    },
    goToLanguageEditor(languageId){
      this.$router.push({name: 'LanguageEditor', params: { languageId }})
    },
    goToTeacherLogin(){
      this.$router.push({name: 'TeacherLogin'})
    },
    goToTeacherHome(params){
      this.$router.push({name: 'TeacherHome', params: params})
    },
  }
}
