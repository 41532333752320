import { Storage } from './index'

function isValidUrl(url) {
  if (!url) {
    return false
  }

  //eslint-disable-next-line
  const regexQuery = /^(http(s)?:\/\/)[^\s$.?#].[^\s]*$/;
  const newUrl = new RegExp(regexQuery);
  return newUrl.test(url);
}

function isValidFile(validFormats, file) {
  if (!validFormats || !file) {
    return false
  }

  return validFormats.indexOf(file.type) > -1;
}

function isEmptyObj(obj) {
  return Object.keys(obj).length === 0;
}

function padNumber(num, size) {
  let s = String(num);
  while (s.length < (size || 2)) {s = "0" + s;}
  return s;
}

function stringToTimeFormat(str) {
  return str.match(/.{1,2}/g);
}

function timeFormatToInt(timeVal) {
  return parseInt(timeVal.split(":").join(""))
}

function splitArrayInEqualsPart(arr, size) {
  const result = new Array(Math.ceil(arr.length / size)).fill().map(() => arr.splice(0, size))
  return result
}
function strIsNumeric(value) {
  return /^\d+$/.test(value);
}

function isNumericFormat(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

function formatCurrency(value) {
    const globalSetup = Storage.getGameGlobalSetup()
    let valueFormatted = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    if(globalSetup.currencySymbolPosition==='Left') {
        valueFormatted = globalSetup.currencySymbol + " " + valueFormatted
    }else{
        valueFormatted = valueFormatted + " " + globalSetup.currencySymbol
    }
    return valueFormatted;
}

function isLongTextToBtn(text) {
  return text ? text.length > 10 : false
}

function compareValues(key, order = 'asc') {
  return function innerSort(a, b) {
    if (!Object.prototype.hasOwnProperty.call(a, key) || !Object.prototype.hasOwnProperty.call(b, key)) {
      // property doesn't exist on either object
      return 0;
    }

    const varA = (typeof a[key] === 'string')
      ? a[key].toUpperCase() : a[key];
    const varB = (typeof b[key] === 'string')
      ? b[key].toUpperCase() : b[key];

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return (
      (order === 'desc') ? (comparison * -1) : comparison
    );
  };
}

export default {
  isValidUrl,
  isValidFile,
  isEmptyObj,
  padNumber,
  stringToTimeFormat,
  timeFormatToInt,
  splitArrayInEqualsPart,
  strIsNumeric,
  isNumericFormat,
  formatCurrency,
  isLongTextToBtn,
  compareValues
}
