import TeacherLoginView from "../TeacherLoginView.vue"
import TeacherLogin from "../Pages/TeacherLogin.vue"

const routes = [
  {
    path: '',
    component: TeacherLoginView,
    children: [
      {
        path: '',
        name: 'TeacherLogin',
        component: TeacherLogin,
      },
    ]
  }
]

export default routes
