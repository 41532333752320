import TeacherHomeView from "../TeacherHomeView.vue"
import TeacherHome from "../Pages/TeacherHome.vue"

const routes = [
  {
    path: 'home',
    component: TeacherHomeView,
    redirect: { name: 'TeacherHome' },
    children: [
      {
        path: '',
        name: 'TeacherHome',
        component: TeacherHome,
        meta: {gameRequired: true},
        props: { fromLoginPage: false }
      },
    ]
  }
]

export default routes
