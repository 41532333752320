export default {
  INITIAL_STEP: 1,
  GAME_MODE: {
    INDIVIDUAL: { value: 1, text: 'ad_setupgame_mode_individual', id: 1 },
    SHARED: { value: 2, text: 'ad_setupgame_mode_shared', id: 2 }
  },
  GAME_STATUS: {
    CREATED: 1,
    IN_PROCESS: 2
  },
  TOPPINGS: {
    MAX: 3,
    MIN: 1
  },
  STUDENTS_PER_TEAM: {
    MAX: 5,
    MIN: 1
  },
  PLAYER_CHANGE_TIME: 3
}
