import {http} from '@/http'
import {Storage} from '@/core/Utils'

const resource = "/api/auth";

export default {
  login(username, password) {
    return http.post(`${resource}/login/`, {email: username, password: password})
      .then(response => {
          this.storeUserCredentials(response.user);
          return response
        }
      );
  },
  loginWithToken(token) {
    return http.post(`${resource}/refreshtoken/`, {refreshToken: token})
      .then(response => {
        const user = Storage.getAdminUser()
        // get new access token
        user.token = response.user.token
        this.storeUserCredentials(user);
        return user
      });
  },
  storeUserCredentials(credentials) {
    Storage.setAdminUser(credentials)
  },
  checkJWTtoken(token) {
    return http.post(`${resource}/token/verify/`, {token: token})
  },
  createUser(payload) {
    return http.post(`${resource}`, payload)
  },
  logout() {
    Storage.clearAdminData()
  },
}
