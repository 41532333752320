import Vue from 'vue'
import VueRouter from 'vue-router'
import AdminRoutes from '../modules/Admin/routes'
import TeacherRoutes from '../modules/Teacher/routes'
import { Storage } from '@/core/Utils'

Vue.use(VueRouter);

let routes = [];

routes = routes.concat(AdminRoutes, TeacherRoutes)

const router = new VueRouter({
  routes,
})

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const loggedIn = !!Storage.getAdminUser().id
  const game = !!Storage.getGameSetup().gameId

  if (to.meta.authRequired && !loggedIn) {
    return next({name: 'AdminLogin'})
  } else if (to.name === 'AdminLogin' && loggedIn) {
    return next({name: 'AdminHome'})
  } else if (to.meta.gameRequired && !game) {
    return next({name: 'TeacherLogin'})
  } else {
    next()
  }
})

export default router;

