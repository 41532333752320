<template>
  <b-modal ref="modal"
           centered 
           modal-class="custom-modal custom-modal--not-header custom-modal--not-footer">
    <div class="wrapper-body">
      <h3 class="main-title-modal mb-1" tabindex="0"
          :aria-label="$t('ad_setupgame_duration')">{{ $t("ad_setupgame_duration") }}</h3>
      <p class="text-modal text-center" tabindex="0"
         :aria-label="$t('ad_setupgame_duration_instructions_msg')">{{ $t("ad_setupgame_duration_instructions_msg") }}</p>
      <div class="mt-4">
        <b-button @click="onCancel()" class="common-btn common-btn-secondary" 
                  :aria-label="$t('ad_button_ok')">{{ $t("ad_button_ok") }}</b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
  export default {
    name: "GameDurationModal",
    props: {},
    data(){
      return {
      }
    },
    mounted(){},
    methods: {
      showModal(){
        this.$refs['modal'].show()
      },
      hideModal(){
        this.$refs['modal'].hide()
      },
      onCancel(){
        this.hideModal();
      }
    }
  };
</script>

<style lang="stylus" scoped>

>>> .custom-modal
  .modal-dialog
    max-width 600px
  .wrapper-body
    display flex
    flex-direction column
    justify-content center
    align-items center
    min-height 300px
</style>
