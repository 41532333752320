<template>
  <div class="mt-4">
    <general-set-up v-if="showGeneralSetUp"
                    :data-setup="dataGeneralSetUp"
                    :global-data="globalData"
                    @updateGameMode="updateDataTeamGameMode"
                    @updateGameSetupData="updateGameSetupData"
                    @startGetTeams="startGetTeams"
                    @resetGame="onResetGame"/>

    <team-set-up v-if="showTeamSetUp"
                 :team-setup="dataTeamSetUp"
                 ref="teamSetUp"/>

    <div class="d-flex justify-content-end mt-4" v-if="showBtnStartGame">
      <b-button type="button"
                class="common-btn common-btn-secondary common-btn--small"
                @click="startGame"
                :disabled="disabledStartGame"
                :aria-label="$t('ad_setupgame_start_button')"
                ref="btnStartGame">
        {{ $t("ad_setupgame_start_button") }}
      </b-button>
    </div>
  </div>
</template>

<script>

  import { Utils } from '@/core/Utils'
  import { GameSetup } from '../Services'
  import { TeacherConstants } from '../Constants'
  import GeneralSetUp from "./GeneralSetUp.vue"
  import TeamSetUp from "./TeamSetUp.vue"

  export default {
    name: "GameSetUpStep",
    components: { GeneralSetUp, TeamSetUp },
    props: ['globalData'],
    data(){
      return {
        showGeneralSetUp: false,
        showTeamSetUp: false,
        dataGeneralSetUp: {},
        dataTeamSetUp: {
          gameCode: null,
          gameMode: TeacherConstants.GAME_MODE.INDIVIDUAL.value,
          gameId: null
        },
        disabledStartGame: true,
        showBtnStartGame: false
      }
    },
    methods: {
      updateDataTeamGameMode(gameMode){
        this.dataTeamSetUp.gameMode = gameMode
      },
      updateGameSetupData(){
        const game = GameSetup.getStorageGame()
        if(!Utils.isEmptyObj(game)){
          this.dataTeamSetUp.gameCode = game.gameCode,
          this.disabledStartGame = false
          this.showBtnStartGame = !!game.gameCode
        }
      },
      startGetTeams(){
        this.blurElement(this.$refs.btnStartGame);
        setTimeout(()=> {
          this.$refs["teamSetUp"].startGetTeamsInterval()
        }, 1000)
      },
      clearInterval(){
        this.$refs["teamSetUp"].stopGetListByInterval()
      },
      startGame(){
        this.clearInterval()
        GameSetup.startGame(this.dataTeamSetUp.gameCode).then((response) => {
          if (response) {
            const gameCode = response.gameCode
            const gameStatus = response.status
            const gameStartedAt = response.startedAt
            this.$emit('gameStart', { gameCode, gameStatus, gameStartedAt })
          }
        }).catch(() => {
          // TODO: show error message
        })

      },
      onResetGame(){
        this.clearInterval()
        GameSetup.resetGame(this.dataTeamSetUp.gameCode)
        this.$emit('updateCurrentStep', 'gameSetUp')
      }
    },
    beforeMount(){
      const game = GameSetup.getStorageGame()
      if(!Utils.isEmptyObj(game)){
        this.dataGeneralSetUp = game
        this.dataTeamSetUp.gameCode = game.gameCode
        this.dataTeamSetUp.gameId = game.gameId
        this.dataTeamSetUp.gameMode = game.gameMode

        this.disabledStartGame = !game.gameCode
        this.showBtnStartGame = !!game.gameCode

        this.showGeneralSetUp = true
        this.showTeamSetUp = true
      }
    }
  };
</script>

<style lang="stylus" scoped>
</style>
