<template>
  <div class="wrapper-page">
    <Spinner/>
    <app-header :is-teacher-page="isTeacherPage"/>
    <div class="content-inner-page" :class="innerPageClass">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
  import AppHeader from './AppHeader.vue'
  import Spinner from '@/core/Components/Spinner.vue'

  export default {
    name: "PageBase",
    components: { AppHeader, Spinner },
    props: {
      isTeacherPage: {
        type: Boolean,
        required: false,
        default: false
      },
      innerPageClass: {
        type: Array,
        required: false,
        default: () => []
      }
    },
    data(){
      return {}
    },
  };
</script>

<style lang="stylus" scoped>
  @import "styles/page_base.styl";
</style>
