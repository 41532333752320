<template>
  <div class="container-logo">
    <b-link @click="goToTeacherLogin"
            @keypress.prevent.space="goToTeacherLogin"
            :to="{name: 'AdminTeacher'}"
            aria-label="Plenty of Pizza"
            v-if="isTeacherForm">
      <img src="@/core/Assets/PlentyOfPizza_Logo.png" alt="Plenty Of Pizza" class="plenty-of-pizza-logo">
    </b-link>
    <b-link @click="goToAdminHome"
            @keypress.prevent.space="goToAdminHome"
            :to="{name: 'AdminLogin'}"
            aria-label="Junior Achievement - Plenty of Pizza Game"
            v-else>
      <img src="@/core/Assets/logo-ja-blue-black.svg" alt="Junior Achievement" tabindex="-1">
      <h1 class="title-header text-uppercase text-center" tabindex="-1">Plenty of Pizza Game</h1>
    </b-link>
  </div>
</template>

<script>
  export default {
    name: "HeaderForm",
    props: {
      isTeacherForm: {
        type: Boolean,
        required: false,
        default: false
      }
    }
  };
</script>

<style lang="stylus" scoped>
  @import "styles/headerForm.styl";
</style>
