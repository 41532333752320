<template>
  <div class="wrapper-security-page">
    <router-view></router-view>
  </div>
</template>

<script>
  export default {
    name: "SecurityView",
  };
</script>

<style lang="stylus" scoped>
  @import "Styles/styles.styl";
</style>
