import Vue from 'vue'
import 'bootstrap/dist/css/bootstrap.min.css'
import "bootstrap-vue/dist/bootstrap-vue.css"
import BootstrapVue from 'bootstrap-vue'
import './core/Styles/main.styl'
import Vuelidate from 'vuelidate'
import App from './App.vue'
import router from './router'
import store from './store'
import titleMixin from './core/Mixins/titleMixin'
import redirectToPage from './core/Mixins/redirectToPage'
import commonMixin from './core/Mixins/commonMixin'
import { UtilsI18n } from './core/Utils'
import VueHtml2Canvas from 'vue-html2canvas'


Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(Vuelidate)
Vue.use(VueHtml2Canvas)
Vue.mixin(titleMixin)
Vue.mixin(redirectToPage)
Vue.mixin(commonMixin)


UtilsI18n.initI18n().then(function(i18n) {
  new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
  }).$mount('#app')
})
