<template>
  <div class="container-setup-card container-setup-card--toppings">
    <div>
      <h4
        class="text-uppercase custom-title title-h4 mb-4"
        tabindex="0"
        :aria-label="$t('ad_dashboard_toppings')"
      >
        {{ $t("ad_dashboard_toppings") }}
      </h4>
      <div class="container-toppings-actions">
        <div class="common-card common-card--add-toppings">
          <h6
            class="custom-title title-h6 mb-0 mr-2"
            tabindex="0"
            :aria-label="$t('ad_dashboard_new_topping')"
          >
            {{ $t("ad_dashboard_new_topping") }}:
          </h6>

          <b-button
            class="common-btn common-btn-secondary common-btn--small"
            @click="showAddNewToppingsModal"
            tabindex="0"
            :aria-label="$t('ad_button_add')"
            ref="btnNewToppings"
            >{{ $t("ad_button_add") }}</b-button
          >
        </div>
        <div class="common-card">
          <div class="text-center mr-2">
            <h6
              class="custom-title title-h6 mb-0"
              tabindex="0"
              :aria-label="$t('ad_dashboard_toppings')"
            >
              {{ $t("ad_dashboard_toppings") }}:
            </h6>
            <div
              class="smallest-text"
              tabindex="0"
              :aria-label="
                $t('ad_dashboard_select_6_toppings', { num: numToppings })
              "
            >
              {{ $t("ad_dashboard_select_6_toppings", { num: numToppings }) }}
            </div>
          </div>

          <b-button
            class="common-btn common-btn-secondary common-btn--small"
            @click="showSelectToppingsModal"
            tabindex="0"
            :aria-label="$t('ad_button_select')"
            ref="btnGameToppings"
            >{{ $t("ad_button_select") }}</b-button
          >
        </div>
      </div>
    </div>
    <div>
      <add-new-toppings-modal
        ref="add_toppings_modal"
        @addTopping="addToppingToMainList"
      />
      <select-toppings-modal
        v-if="showSelectToppings"
        ref="select_6_toppings_modal"
        :toppings-data="dataToSelectToppings"
      />
    </div>
  </div>
</template>

<script>
import AddNewToppingsModal from "./AddNewToppingsModal.vue";
import SelectToppingsModal from "./SelectToppingsModal.vue";

export default {
  name: "GameToppings",
  components: { AddNewToppingsModal, SelectToppingsModal },
  props: {
    selectToppingsData: {
      type: Object,
      required: true,
      default: () => {
        return {
          toppingsList: [],
          setupSection: null,
          selectedToppingIds: null
        };
      }
    }
  },
  data() {
    return {
      dataToSelectToppings: {
        toppingsList: [],
        section: null,
        selectedToppingIds: null
      },
      showSelectToppings: false,
      numToppings: 4
    };
  },
  mounted() {
    this.dataToSelectToppings = {
      toppingsList: this.selectToppingsData.toppingsList,
      setupSection: this.selectToppingsData.setupSection,
      selectedToppingIds: this.selectToppingsData.selectedToppingIds
    };
    this.showSelectToppings = true;
  },
  methods: {
    showAddNewToppingsModal() {
      this.blurElement(this.$refs.btnNewToppings);
      this.$refs["add_toppings_modal"].showModal();
    },
    showSelectToppingsModal() {
      this.blurElement(this.$refs.btnGameToppings);
      this.$refs["select_6_toppings_modal"].showModal();
    },
    addToppingToMainList(topping) {
      this.dataToSelectToppings.toppingsList.push(topping);
    }
  }
};
</script>

<style lang="stylus" scoped>
@import "Styles/game_toppings.styl";
</style>
