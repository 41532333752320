<template>
  <div class="container-step-tab" :class="{'is-disabled': stepIsDisabled}"
       :tabindex="stepIsDisabled ? '-1' : '0'" :aria-label="stepText">
    <div class="step-tab--step"><div class="w-100">{{ step }}</div></div>
    <div class="step-tab--text">{{ stepText }}</div>
  </div>
</template>

<script>

  const INITIAL_STEP = 1

  export default {
    name: "StepTab",
    props: {
      step: {
        type: [Number, String],
        required: false,
        default: INITIAL_STEP
      },
      stepText: {
        type: String,
        required: false,
        default: ""
      },
      isDisabled: {
        type: Boolean,
        required: false,
        default: false
      },
      currentStep: {
        type: [Number, String],
        required: true,
        default: INITIAL_STEP
      }
    },
    data(){
      return {}
    },
    computed: {
      stepIsDisabled(){
        if(this.isDisabled){
          return true
        }
        if(parseInt(this.step) == parseInt(this.currentStep)){
          return false
        }
        return true
      }
    }
  };
</script>

<style lang="stylus" scoped>
  @import "styles/step_tab.styl";
</style>
