<template>
<div class="no-print">
  <div class="row">
    <div class="col-lg-10 col-md-8 pr-md-1">
      <div class="custom-cell">
        <b-progress :value="progression" class="m-1 custom-progress" id="gameProgressBar">
          <template v-slot:default>
            <div class="progress-bar" :style="{width: `${progression}%`}" tabindex="-1"></div>
          </template>
        </b-progress>
      </div>
    </div>
    <div class="col-lg-2 col-md-4 pl-md-1">
      <div class="custom-cell" tabindex="0" :aria-label="remainingTimeAriaLabel">
        <div class="count-down m-1">
          <span>{{ remainingTimeText }}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="row justify-content-end no-gutters mt-4 mb-3">
    <div class="col-md-auto px-md-2">
      <b-button @click="newGame()" :disabled="!showResult"
                class="common-btn mb-3 common-btn-secondary common-btn--small"
                :aria-label="$t('ad_gameinprogress_new_game')" ref="btnNewGame">
        {{ $t("ad_gameinprogress_new_game") }}
      </b-button>
    </div>

    <div class="col-md-auto pl-md-2">
      <b-button @click="seeResults()" :disabled="!gameEnded"
                class="common-btn mb-3 common-btn-secondary common-btn--small"
                :aria-label="$t('ad_gameinprogress_see_results')"
                ref="btnSeeResults">
        {{ $t("ad_gameinprogress_see_results") }}
      </b-button>
    </div>
  </div>
  <new-game-modal ref="new_game_modal" v-on="$listeners"/>
</div>
</template>

<script>
  import { GameSetup } from "../Services"
  import { TeacherConstants } from '../Constants'
  import NewGameModal from "./NewGameModal.vue"

  export default {
    name: "GameProgression",
    components: {NewGameModal},
    props: [],
    data(){
      return {
        progression: 0,
        intervalCountDown: null,
        gameStartedAt: null,
        gameWillEnd: null,
        remainingTimeText: '00:00:00',
        remainingTimeSeconds: 0,
        gameEnded: false,
        showResult: false
      }
    },
    beforeMount(){
      let game = GameSetup.getStorageGame()
      if (game) {
        this.gameStartedAt = new Date(game.startedAt)
        this.gameWillEnd = this.getGameEndDate(game)
        const now = new Date()
        if (Date.parse(now) < Date.parse(this.gameWillEnd)) {
          this.initCountDown()
        }
        else {
          this.progression = 100
          this.gameEnded = true
        }
      }
    },
    destroyed(){
      this.clearIntervalCountDown()
    },
    methods: {
      getGameEndDate(game) {
        const startedAt = new Date(game.startedAt)
        let duration = 0
        if (game.gameMode == TeacherConstants.GAME_MODE.INDIVIDUAL.value) {
          duration = game.gameDuration + TeacherConstants.PLAYER_CHANGE_TIME
        }
        else {
          duration = (game.gameDuration + TeacherConstants.PLAYER_CHANGE_TIME) * game.maxStudentsPerTeam
        }
        startedAt.setSeconds(startedAt.getSeconds() + duration);
        return startedAt
      },
      calculateProgression() {
        const now = new Date()
        if (Date.parse(now) < Date.parse(this.gameWillEnd)) {
          const totalTime = Math.abs(this.gameWillEnd - this.gameStartedAt);
          const progressTime = Math.abs(now - this.gameStartedAt);

          this.progression = Math.round(progressTime / totalTime * 100)

          this.remainingTime = totalTime - progressTime + 1000
          this.remainingTimeText = new Date(this.remainingTime).toISOString().substr(11, 8)
        }
        else {
          clearInterval(this.intervalCountDown)
          this.progression = 100
          this.remainingTimeText = '00:00:00'
          this.gameEnded = true
        }
      },
      initCountDown(){
        this.intervalCountDown = setInterval(() => {
          this.calculateProgression()
        }, 1000)
      },
      clearIntervalCountDown(){
        clearInterval(this.intervalCountDown)
      },
      newGame(){
        this.blurElement(this.$refs.btnNewGame);
        this.$refs["new_game_modal"].showModal()
      },
      seeResults(){
        this.blurElement(this.$refs.btnSeeResults);
        this.showResult = true
        this.$emit("onShowResults")
      }
    },
    computed: {
      remainingTimeAriaLabel(){
        const time = this.remainingTimeText.split(":")
        return this.$t("ad_gameinprogress_remaining_time", {hour: time[0], min: time[1], sec: time[2]})
      }
    }
  };
</script>

<style lang="stylus" scoped>
@import "~@/core/Styles/mixins.styl"
@import "~@/core/Styles/variables.styl"

.common-btn
  min-width 10rem

.custom-cell
  border 3px solid color-17
  border-radius(5px)
  background-color color-9

.custom-progress
  height 35px
  background-color color-9
  & >>> .progress-bar
    background-color color-17
    border-radius(5px)

.count-down
  font font-phoreuscherokee-regular
  font-size 16px
  letter-spacing 2.4px
  height 35px
  display flex
  justify-content center
  align-items center
</style>
