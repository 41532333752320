<template>
  <base-form>
    <template v-slot:content>
      <header-form/>

      <h3 class="title-form-page" tabindex="0" :aria-label="$t('ad_resetpassword_title')">{{ $t("ad_resetpassword_title") }}</h3>

      <b-form @submit.prevent="onSubmit" class="mr-auto ml-auto mt-5 w-75">
        <b-form-group label-for="password_field">
          <b-form-input
            id="password_field"
            v-model="form.password"
            type="password"
            :placeholder='$t("ad_label_new_password")'
            class="common-input"
          ></b-form-input>

          <div>

            <div class="container-alert mt-1" v-if="passwordError">
              <b-alert variant="danger" dismissible
                       @dismissed="passwordError=false"
                       show class="custom-alert-ja has-icon-alert"
                       tabindex="0" :aria-label="$t('ad_error_required')">
                <div class="title-alert">{{ $t("ad_error_required") }}</div>
                <div class="alert-type-icon icon-cancel-circled"></div>
              </b-alert>
            </div>
          </div>

        </b-form-group>

        <b-form-group label-for="confirm_password_field">
          <b-form-input
            id="confirm_password_field"
            v-model="form.confirmPassword"
            type="password"
            :placeholder='$t("ad_label_repeat_password")'
            class="common-input"
          ></b-form-input>

          <div>
            <div class="container-alert mt-1" v-if="confirmPasswordError">
              <b-alert variant="danger" dismissible
                       @dismissed="confirmPasswordError=null"
                       show class="custom-alert-ja has-icon-alert"
                       tabindex="0" :aria-label="confirmPasswordError">
                <div class="title-alert">{{ confirmPasswordError }}</div>
                <div class="alert-type-icon icon-cancel-circled"></div>
              </b-alert>
            </div>

          </div>
        </b-form-group>

        <div class="container-alert" v-if="showSuccessMessage">
          <b-alert variant="success" show class="custom-alert-ja has-icon-alert"
                   tabindex="0" :aria-label="$t('ad_resetpassword_success')">
            <div></div>
            <div class="title-alert">
              {{ $t("ad_resetpassword_success") }}
            </div>
            <div class="alert-type-icon icon-ok-circled"></div>
          </b-alert>
        </div>
        <div class="container-btns-form mt-4"
             :class="{'btns-max-width': isLongTextToBtn() }" v-else>

          <b-button type="submit" class="common-btn common-btn-green common-btn-secondary"
                    tabindex="0" :aria-label="$t('ad_resetpassword_reset_button')" id="btnSubmitForm">
            {{ $t("ad_resetpassword_reset_button") }}
          </b-button>

          <b-button @click="goToAdminLogin"
                    @keypress.prevent.space="goToAdminLogin"
                    class="common-btn common-btn-green common-btn-secondary"
                    tabindex="0" :aria-label="$t('ad_button_cancel')" type="button">
            {{ $t("ad_button_cancel") }}
          </b-button>
        </div>

        <div class="container-alert mt-0" v-if="serviceError && tokenError">
          <b-alert show variant="danger" dismissible @dismissed="tokenError=false"
                   class="custom-alert-ja has-icon-alert">
            <div class="title-alert" :aria-label="tokenError" tabindex="0">
              {{ tokenError }}
            </div>
            <div class="alert-type-icon icon-cancel-circled"></div>
          </b-alert>
        </div>

        <div class="container-alert mt-0" v-if="serviceError && passwordErrors.length > 0">
          <b-alert show variant="danger" dismissible @dismissed="serviceError=false; passwordErrors=[]"
                   class="custom-alert-ja has-icon-alert">
            <div class="title-alert" v-for="(error, index) in passwordErrors" :key="`passwordErrors_${index}`">
              <div :aria-label="error" tabindex="0">{{ error }}</div>
            </div>
            <div class="alert-type-icon icon-cancel-circled"></div>
          </b-alert>
        </div>

      </b-form>
    </template>
  </base-form>
</template>

<script>
  import { Utils } from '@/core/Utils'
  import { UserService } from '../Services';
  import { BaseForm, HeaderForm } from '@/core/Components'

  export default {
    name: "AdminResetPassword",
    components: { BaseForm, HeaderForm },
    data(){
      return {
        form: { token: "", email: "", password: "", confirmPassword: "" },
        passwordError: false,
        confirmPasswordError: "",
        passwordErrors: [],
        tokenError: false,
        serviceError: false,
        showSuccessMessage: false
      }
    },
    methods: {
      onSubmit() {
        this.resetPassword()
        this.blurElement(null, 'btnSubmitForm')
      },
      resetPassword(){
        let validForm = true
        this.passwordErrors = []
        this.tokenError = false
        this.serviceError = false

        const form = this.form;
        form.token = this.$route.query.token;
        form.email = this.$route.query.email;

        const password = form.password;
        const confirmPassword = form.confirmPassword;
        this.passwordError = password === "";

        if(confirmPassword === "")this.confirmPasswordError = this.$t("ad_error_required");
        if(password !== confirmPassword) this.confirmPasswordError =  this.$t("ad_error_confirm_password");
        if(confirmPassword !== "" && password === confirmPassword) this.confirmPasswordError =  "";

        if(password && confirmPassword && password === confirmPassword) {
          this.passwordErrors = []
          this.tokenError = false
          this.serviceError = false

          if(!form.token || !form.email){
            validForm = false
            this.tokenError = this.$t("ad_resetpassword_token_invalid")
          }

          if(!validForm){
            return
          }

          UserService.resetPassword(form).then(() => {
            this.showSuccessMessage = true;
            setTimeout(() => {
              this.goToAdminLogin();
            }, 1500)
          }).catch(err => {
            this.showSuccessMessage = false;
            this.serviceError = this.$t("ad_error_unexpected")
            if (err) {
              if (err.errors && err.errors['Password']) {
                err.errors['Password'].forEach(error => this.passwordErrors.push(error))
              }

              if (err.errors && err.errors['InvalidToken']) {
                this.tokenError = this.$t("ad_resetpassword_token_invalid")
              }
            }
          })
        }
      },
      isLongTextToBtn(){
        const firstBtn = Utils.isLongTextToBtn(this.$t('ad_resetpassword_reset_button'))
        const secondBtn = Utils.isLongTextToBtn(this.$t('ad_button_cancel'))
        return firstBtn || secondBtn
      }
    }
  };
</script>

<style lang="stylus" scoped>
</style>
