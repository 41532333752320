import {http} from '@/http'

const resource = "/api/adminSetup";

export default {
  getGlobalSetup() {
    return http.get(`${resource}/Global/`)
  },
  setGlobalSetup(payload) {
    return http.post(`${resource}/Global/`, payload)
  },
  addTopping(payload) {
    return http.post(`${resource}/Topping/`, payload)
  },
}
